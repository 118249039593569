import { Box, FormControl, Modal, RadioGroup } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { Button, InputField, WarnModal } from "../../components";
import {
  useDeleteAgentMutation,
  useLazyGetAgentsByIdQuery,
  useLazyGetAllAgentsQuery,
  usePostAddAgentMutation,
  useUpdateAgentsMutation,
} from "../../service/Common";
import { Loader, errorToast, successToast } from "../../helpers";
import PhoneInput from "react-phone-input-2";
import useTranslation from "../../hooks/Translation";

const ManageAgents = () => {
  const translation = useTranslation();
  const [value, setValue] = useState("Home");
  const style = generateResponsiveStyle();
  const [addAgents, { isLoading }] = usePostAddAgentMutation();
  const [allAgents] = useLazyGetAllAgentsQuery();
  const [getAgentsById] = useLazyGetAgentsByIdQuery();
  const [deleteAgent] = useDeleteAgentMutation();
  const [updateAgent, updateAgentData] = useUpdateAgentsMutation();

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  const [agentData, setAgentData] = useState<any>();
  const [allAddress, setAllAddress] = useState<any>([]);
  const [openWarn, setOpenWarn] = useState(false);

  const [isAdd, setIsAdd] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue("countryCode", country?.dialCode);
  };

  const handleDeleteAgent = async (id: string) => {
    try {
      const res = await deleteAgent({ id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(translation.toast_messages.delete_agent);
        getAllAgents();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllAgents = async () => {
    try {
      const response = await allAgents({}).unwrap();
      if (response?.statusCode === 201 || 200) {
        if (response?.data) {
          setAgentData(response?.data?.user || []);
          // dispatch(
          //   addressData({
          //     address: response?.data?.address,
          //   })
          // );
          // setSelectedId(response?.data?.address[0]?._id);
        }
      } else {
        setAllAddress([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleEditAgent = async (id: string) => {
    try {
      const res = await getAgentsById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setIsAdd(false);
        setFullName(res?.data?.agentName);
        setCountryCode(res?.data?.countryCode);
        setPhone(res?.data?.phone);
        setSelectedId(res?.data?._id);
        setOpenModal(true);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: phone || "",
      countryCode: countryCode || "+966",
      agentName: fullName || "",
    },

    validationSchema: Yup.object({
      agentName: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two),
      phone: Yup.string()
        .required(translation.validations.required_field)
        .min(6, translation.validations.phone_min)
        .max(16, translation.validations.phone_max),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        agentName: formik.values.agentName,
        phone: formik.values.phone,
        countryCode: formik.values.countryCode,
      };

      try {
        if (isAdd) {
          const res = await addAgents(body).unwrap();
          if (res?.statusCode === 200) {
            successToast(translation.toast_messages.agent_add);
            setOpenModal(false);
            formik.resetForm();
            getAllAgents();
          }
        } else {
          const res = await updateAgent({ body, id: selectedId }).unwrap();
          if (res?.statusCode === 200) {
            setSelectedId(selectedId);
            // getAllAddress();.a.addAgent
            setOpenModal(false);
            successToast(translation.toast_messages.agent_update);
            formik.resetForm();
            getAllAgents();
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }

      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    getAllAgents();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading || updateAgentData?.isLoading} />
      <div className="main" style={{ padding: 0 }}>
        <div className="flx_dv_prfl">
          <div
            className="agt_btn"
            onClick={() => {
              setOpenModal(true);
              setFullName("");
              setCountryCode("");
              setPhone("");
              setIsAdd(true);
            }}
          >
            <figure>
              <img src="/static/images/add_address.png" alt="" />
            </figure>
            {translation.service.addNewAgent}
          </div>
        </div>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {agentData?.map((item: any) => (
              <div className="adrss_outr">
                <div className="adrs_mn">
                  <div className="adrs_lft">
                    <div className="adrs_dtls">
                      <h4>{item?.agentName}</h4>
                      <p>{`${item?.countryCode} ${item?.phone}`}</p>
                    </div>
                  </div>
                  <div className="edit_adrss">
                    <button onClick={() => handleEditAgent(item?._id)}>
                      {translation.profile.edit}
                    </button>
                    <figure
                      onClick={() => {
                        setSelectedId(item?._id);
                        setOpenWarn(true);
                      }}
                    >
                      <img src="/static/images/delete.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "50px",
              }}
            >
              <h2
                style={{
                  textAlign: "center",

                  fontSize: "22px",
                }}
              >
                {translation.service.addNewAgent}
              </h2>
              <div>
                <CloseIcon onClick={() => setOpenModal(false)} />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <InputField
                name="agentName"
                id="agentName"
                placeholder={translation.service.agentName}
                value={formik.values.agentName}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.agentName && formik.errors.agentName}
              />
              <div className="form_control">
                <PhoneInput
                  country={"in"}
                  value={formik.values.countryCode + formik.values.phone}
                  onChange={(phone: any, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                  placeholder="0 (000) 000-000"
                  enableSearch={false}
                  inputStyle={{
                    width: "100%",
                    backgroundColor: "#EBEBEB",
                  }}
                  onlyCountries={["in", "sa"]}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="err_msg">
                    {formik.touched.phone && formik.errors.phone}
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginBottom: 70 }} />
              <Button value={translation.Globals.submit} />
            </form>
          </div>
        </Box>
      </Modal>

      <WarnModal
        open={openWarn}
        setOpen={setOpenWarn}
        handleDelete={() => handleDeleteAgent(selectedId)}
        name={translation.service.agent}
      />
    </>
  );
};

export default ManageAgents;
