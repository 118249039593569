import React from "react";
import { Button, InputField } from "../../components";
import { Input, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { useUpdateProfileMutation } from "../../service/Auth";
import { UploadMedia } from "../../utils/uploadMedia";
import useTranslation from "../../hooks/Translation";

const EditProfile = () => {
  const translation = useTranslation();

  const dispatch = useAppDispatch();
  const user = useAuth();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue("countryCode", country?.dialCode);
  };

  // for update profile
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: user?.fullName || "",
      userName: user?.userName || "",
      email: user?.email || "",
      phone: user?.phone || "",
      countryCode: user?.countryCode || "+966",
      address: user?.address || "",
      image: user?.image || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
      fullName: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      userName: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      address: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      phone: Yup.string()
        .required(translation.validations.required_field)
        .min(6, translation.validations.phone_min)
        .max(16, translation.validations.phone_max),
    }),
    onSubmit: async (values: any) => {
      formik.setSubmitting(true);
      let body = {
        fullName: formik.values.fullName,
        userName: formik.values.userName,
        address: formik.values.address,
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: formik.values.countryCode,
        ...(formik.values.image ? { image: formik.values.image } : {}),
      };

      try {
        const response = await updateProfile(body).unwrap();
        if (response?.statusCode === 200) {
          successToast(translation.toast_messages.profile_updated);
          const token = getFromStorage(STORAGE_KEYS.token);
          dispatch(
            setCredentials({
              token: JSON.parse(`${token}`),
              user: response?.data || null,
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("image", res?.data);
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast(translation.toast_messages.try_again);
      }
    }
  };
  return (
    <div className="main">
      {/* <h2>Profile</h2> */}
      <Loader isLoad={isLoading} />
      <div className="image">
        <label htmlFor="icon-button-file">
          <Input
            sx={{ display: "none" }}
            id="icon-button-file"
            type="file"
            inputProps={{
              accept: ".png, .jpg, .jpeg, .svg",
            }}
            onChange={handleImageUpload}
          />

          <figure>
            {!formik.values.image ? (
              <img
                className="img_style2"
                src="/static/images/userDiv.png"
                alt=""
              />
            ) : (
              <img className="img_style" src={formik.values.image} alt="" />
            )}
          </figure>
          <AddIcon />
        </label>
      </div>
      <p className="manage"> {translation.profile.manage_profile}</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="form_control">
          <h3>{translation.Globals.full_name}</h3>
          <InputField
            placeholder={translation.Globals.full_name}
            name="fullName"
            id="fullName"
            value={formik.values.fullName}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
        </div>
        <div className="form_control">
          <h3>{translation.Globals.user_name}</h3>
          <InputField
            placeholder={translation.Globals.user_name}
            name="userName"
            id="userName"
            value={formik.values.userName}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.userName && formik.errors.userName}
          />
        </div>
        <div className="form_control">
          <h3>{translation.Globals.phone_number} </h3>
          <PhoneInput
            country={"in"}
            value={formik.values.countryCode + formik.values.phone}
            onChange={(phone: any, country: any) =>
              handleChangePhone(phone, country)
            }
            onBlur={formik.handleBlur}
            placeholder="0 (000) 000-000"
            enableSearch={false}
            inputStyle={{
              width: "100%",
              backgroundColor: "#EBEBEB",
            }}
            onlyCountries={["in", "sa"]}
          />

          <div style={{ marginBottom: 10 }} />
        </div>
        <div className="form_control">
          <h3>{translation.Auth.Login.email_id}</h3>
          <InputField
            placeholder={translation.Auth.Login.email_id}
            name="email"
            id="email"
            value={formik.values.email}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>

        <div className="form_control">
          <h3>{translation.Globals.address}</h3>
          <InputField
            placeholder={translation.Globals.address}
            name="address"
            id="address"
            value={formik.values.address}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.address && formik.errors.address}
          />
        </div>
        <div className="changePass">
          <h3
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "changePassword",
                })
              )
            }
          >
            {translation.Auth.change_password.change}
          </h3>
        </div>
        <div>
          <Button value={translation.Globals.update} />
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
