import { END_POINTS } from "../helpers";
import { SubscriptionRes } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: User;
  message: string;
};

type Login = {
  userName?: string;
  email?: string;
  password: string;
  deviceType?: string;
  deviceToken?: string | null | any;
}

type PostSignupBody = {
  phone: string;
  countryCode: string | undefined;
};

type PostChangePasswordBody = {
  oldPassword: string;
  password: string;
};

type PostChangePasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutUpdateProfileBody = {
  fullName: string;
  userName: string;
  address?: string;
  email?: string;
  phone?: any;
  countryCode?: any;
  password?: string;
};

type PutUpdateProfileByIdResponse = {
  userId: string;
  body: PutUpdateProfileByIdBody;
};

type PutUpdateProfileByIdBody = {
  documentFrontImage: string;
  documentBackImage: string;
  document: string;
};

type PostLogoutResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      {
        userName?: string,
        email?: string,
        password: string,
        deviceType?: string,
        deviceToken?: string | null | any,
        countryCode?: any,
        phone?: string
      }
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postSignup: builder.mutation<
      CommonResponseType & { data: User },
      PostSignupBody
    >({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),

    forgotPassword: builder.mutation<CommonResponseType, PostSignupBody>({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      PostChangePasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),

    getProfile: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.get_profile,
        method: "GET",
      }),
    }),

    updateProfile: builder.mutation<
      CommonResponseType & { data: User },
      PutUpdateProfileBody
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),

    postLogout: builder.mutation<PostLogoutResponse, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "POST",
      }),
    }),

    otpVerfication: builder.mutation<
      CommonResponseType & { data: string },
      { phone: string; countryCode: string | undefined; code: string }
    >({
      query: (body) => ({
        url: END_POINTS.verify_otp,
        method: "POST",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      {
        statusCode: number;
        data: string;
        message: string;
      },
      { password: string }
    >({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),

    putUpdateProfileById: builder.mutation<
      CommonResponseType,
      PutUpdateProfileByIdResponse
    >({
      query: ({ userId, body }) => ({
        url: `${END_POINTS.update_profile_by_id}/${userId}`,
        method: "PUT",
        body,
      }),
    }),
    getAllSubscription: builder.query<
      CommonResponseType & { data: SubscriptionRes[] },
      {}
    >({
      query: () => ({
        url: END_POINTS.subscription,
        method: "GET",
      }),
    }),
    // add subscription
    addSubsciption: builder.mutation<
      {
        statusCode: number;
        data: string;
        message: string;
      },
      { status: number; subscriptionId: string }
    >({
      query: (body) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  usePostLoginMutation,
  usePostSignupMutation,
  usePostChangePasswordMutation,
  useForgotPasswordMutation,
  useLazyGetProfileQuery,
  usePostLogoutMutation,
  useOtpVerficationMutation,
  useResetPasswordMutation,
  usePutUpdateProfileByIdMutation,
  useUpdateProfileMutation,
  useLazyGetAllSubscriptionQuery,
  useAddSubsciptionMutation,
} = authApi;
