import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  useAddSubsciptionMutation,
  useLazyGetAllSubscriptionQuery,
  useLazyGetProfileQuery,
} from "../../service/Auth";
import { SubscriptionRes } from "../../types/General";
import { setCredentials } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const Subscription = ({ closeModal, setPhone }: props) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const [subscriptionMethod, subscriptionData] =
    useLazyGetAllSubscriptionQuery();
  const [addSub, { isLoading }] = useAddSubsciptionMutation();
  const [getProfile] = useLazyGetProfileQuery();

  const [currentSlide, setCurrentSlide] = useState("0");
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [selectedPlan, setSelectedPlan] = useState("6");
  const [allSubscription, setAllSubscription] = useState<SubscriptionRes[]>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index: any) => {
      const activeItem = allSubscription[index];
      setCurrentSlide(activeItem?._id);
    },
  };

  const getAllSubscription = async () => {
    try {
      const response = await subscriptionMethod({}).unwrap();
      if (response?.statusCode === 200) {
        setAllSubscription(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buySubscription = async () => {
    const body = {
      subscriptionId: currentSlide,
      status: 2,
    };

    try {
      const response = await addSub(body).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.subscriptionAdd);
        getUserDetails();
        dispatch(
          toggleAuthModal({
            isAuthModalVisible: false,
            ModalType: "",
          })
        );
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    getAllSubscription();
  }, []);

  useEffect(() => {
    console.log("useEffect");
    setCurrentSlide(allSubscription?.[1]?._id || "");
    setSelectedPrice(Number(allSubscription?.[0]?.sixMonth || 0));
  }, [allSubscription]);

  return (
    <div>
      <Loader isLoad={isLoading || subscriptionData?.isLoading} />
      <div className="AuthWrap2 sbscrptn">
        <div className="cross dbl">
          <h2>{translation.subscriptions.subscription}</h2>
          <h6
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          >
            {translation.subscriptions.skip}
          </h6>
        </div>
        <div className="topslider">
          <Slider {...settings}>
            {allSubscription?.length
              ? allSubscription?.map((item) => (
                  <div className="slide" key={item?._id}>
                    <figure>
                      <img src={item?.image || ""} />
                    </figure>
                    <h3>{item?.name || ""}</h3>
                    <p>{item?.subscriptionDescription || ""}</p>
                  </div>
                ))
              : null}
          </Slider>
        </div>

        <div className="slider-container">
          {allSubscription?.length
            ? allSubscription?.map((item) => (
                <>
                  {currentSlide === item?._id ? (
                    <>
                      <div
                        onClick={() => {
                          setSelectedPlan("1");
                          setSelectedPrice(Number(item?.oneMonth));
                        }}
                        className={
                          selectedPlan === "1"
                            ? "centerSlide active_plan"
                            : "centerSlide"
                        }
                      >
                        <h3>1</h3>
                        <h5>{translation.subscriptions.month}</h5>
                        <h4 className="">{item?.oneMonth || "0"} SAR</h4>
                        <div className="bnt">
                          {/* <button>Save 35%</button> */}
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setSelectedPlan("6");
                          setSelectedPrice(Number(item?.sixMonth));
                        }}
                        className={
                          selectedPlan === "6"
                            ? "centerSlide active_plan"
                            : "centerSlide"
                        }
                      >
                        <h3>6</h3>
                        <h5>{translation.subscriptions.months}</h5>
                        <h4 className="">{item?.sixMonth || ""} SAR</h4>
                        <div className="bnt">
                          <button>
                            {translation.Globals.save}{" "}
                            {`${item?.saveInSixMonth.toFixed(1) || "0"}%`}
                          </button>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setSelectedPlan("12");
                          setSelectedPrice(Number(item?.oneYear));
                        }}
                        className={
                          selectedPlan === "12"
                            ? "centerSlide active_plan"
                            : "centerSlide"
                        }
                      >
                        <h3>1</h3>
                        <h5>{translation.subscriptions.year}</h5>
                        <h4 className="">{item?.oneYear || "0"} SAR</h4>
                        <div className="bnt">
                          <button>
                            {translation.Globals.save}{" "}
                            {`${item?.saveInOneYear.toFixed(1) || "0"}%`}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ))
            : null}
        </div>

        {/* <p className="descr">
          The point of using Lorem Ipsum is that it has a more-or-less normal
          distribution of letters, as opposed to using ‘Content here, content
          here’, making it look like readable English.
        </p> */}
        <Button
          onClick={buySubscription}
          value={`Get ${selectedPlan} ${
            selectedPlan === "1"
              ? translation.subscriptions.month
              : translation.subscriptions.months
          }  ${translation.subscriptions.for} ${selectedPrice} SAR`}
        />
      </div>
    </div>
  );
};

export default Subscription;
