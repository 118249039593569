import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import cardData from "../../../src/data/ProductData.json";
import { Button, CarPlateCard, Pagination } from "../../components";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import "../filters/filter.scss";
import { useFilterPostMutation } from "../../service/home";
import { errorToast } from "../../helpers";
import { useLocation } from "react-router-dom";
import { useLazyGetAllCateQuery } from "../../service/posts";
import { Category, ListedPost } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

function valuetext(value: number) {
  return `${value}°C`;
}

const Filters = () => {
  const translation = useTranslation();
  const user = useAuth();
  const location = useLocation();
  const { state } = location;

  const [filterMutation] = useFilterPostMutation();
  const [allCategory] = useLazyGetAllCateQuery();

  const [value, setValue] = useState<number[]>([0, 100000]);
  const [filteredPost, setFilteredPost] = useState<ListedPost[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [postType, setPostType] = useState(true);
  const [category, setCategory] = useState<Category[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);
  const [page, setPage] = useState(1);

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleSortingOptions = (event: any) => {
    setSelectedType(event.target.value);
  };

  const sortingOptiopns = [
    { value: "quadruple", label: translation.home.quadruple },
    { value: "triple", label: translation.home.triple },
    { value: "binary", label: translation.home.binary },
    { value: "oddNumbers", label: translation.home.oddNumber },
  ];

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data);
        setSelectedId(response?.data?.[0]?._id || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getFilteredPost = async () => {
    try {
      const response = await filterMutation({
        type: selectedType,
        categoryId: selectedId,
        minPrice: value[0] || 0,
        maxPrice: value[1] || 1000000000,
        limit: 10,
        page: page,
        ...(user?._id ? { userId: user._id } : {}),
      }).unwrap();
      if (response?.statusCode === 200) {
        setFilteredPost(response?.data?.post || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (selectedId && user) {
      getFilteredPost();
    }
  }, [selectedType, selectedId, page, user]);

  const handleClearFilters = () => {
    setSelectedId(category?.[0]?._id);
    setPage(1);
    setSelectedType(state && state !== "showOption" ? state : "");
    setValue([0, 1000000000]);
    // getFilteredPost();
  };
  useEffect(() => {
    getAllCate();
  }, []);

  useEffect(() => {
    if (state && state !== "showOption") {
      setSelectedType(state);
    }
  }, [state]);

  useEffect(() => {
    if (state && state !== "showOption" && selectedType) {
      getFilteredPost();
    }
  }, [state]);

  const checkFavProperties = () => {
    const binaryData = filteredPost || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [filteredPost]);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="filters">
              <h2></h2>
              <div className="sub_catMain">
                <div className="sc_lft">
                  {state === "latest" ||
                  state === "mostLike" ||
                  state === "auction" ? (
                    <div className="sub">
                      <h4>{translation.home.soryBy}</h4>
                      <ul>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={selectedType}
                          onChange={handleSortingOptions}
                        >
                          {sortingOptiopns?.map((option) => (
                            <li key={option.value}>
                              <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              />
                            </li>
                          ))}
                        </RadioGroup>
                      </ul>
                    </div>
                  ) : null}

                  <div className="sub">
                    <h3 style={{ color: "#204E33" }}>
                      {translation.home.filters}
                    </h3>
                    <p>{translation.home.priceRange}</p>
                    <ul
                      style={{ paddingTop: 0, borderBottom: "1px solid white" }}
                    >
                      <li>
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          max={100000}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="sub">
                    <h3>{translation.home.category}</h3>
                    <div className="choosePost1">
                      {category?.length
                        ? category?.map((item, i) => (
                            <div
                              className="box"
                              onClick={() => {
                                if (item?.categoryName === "Public Car") {
                                  setPostType(false);
                                  setSelectedId(item?._id);
                                } else {
                                  setPostType(true);
                                  setSelectedId(item?._id);
                                }
                              }}
                            >
                              <div className="check_mark">
                                {item?.categoryName == "Public Car" ? (
                                  <figure>
                                    <img
                                      src={
                                        !postType
                                          ? "/static/images/tick.png"
                                          : "/static/images/no_tick.png"
                                      }
                                      alt=""
                                    />
                                  </figure>
                                ) : null}
                                {item?.categoryName !== "Public Car" ? (
                                  <figure>
                                    <img
                                      src={
                                        postType
                                          ? "/static/images/tick.png"
                                          : "/static/images/no_tick.png"
                                      }
                                      alt=""
                                    />
                                  </figure>
                                ) : null}
                                <h3>{item?.categoryName || ""}</h3>
                              </div>
                              <figure>
                                {item?.categoryName === "Public Car" ? (
                                  <img
                                    src="/static/images/public_car.png"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/static/images/private_car.png"
                                    alt=""
                                  />
                                )}
                              </figure>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="filter-btn">
                    <Button value="Apply Filters" onClick={getFilteredPost} />
                    <h5 onClick={handleClearFilters}>
                      {translation.home.clearFilter}
                    </h5>
                  </div>
                </div>
                <div className="sc_Ryt">
                  <div className="prodcts_sc">
                    <div className="crds_sec">
                      {filteredPost?.length ? (
                        filteredPost?.map((item) => (
                          <CarPlateCard
                            category={item?.categoryData?.categoryName}
                            posttitle={item?.posttitle}
                            price={item?.price}
                            carPlatenumber={item?.carPlatenumber}
                            _id={item?._id}
                            setFavItems={setFavItems}
                            favItems={favItems}
                            item={item}
                            likeItems={likeItems}
                            setLikeItems={setLikeItems}
                          />
                        ))
                      ) : (
                        <div className="noData top_mt">
                          <figure>
                            <img src="/static/images/noData.webp" alt="" />
                          </figure>
                          <h3> {translation.error.noData}</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {filteredPost?.length ? (
                <Pagination
                  module={filteredPost}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Filters;
