import { END_POINTS } from "../helpers";
import { NotificationRes, SettingRes } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetCmsResponse = {
  data: null;
  statusCode: number;
  message: string;
};

type PostContactUsBody = {
  email: string;
  phone: string;
  name: string;
  message: string;
  countryCode: string;
  subject: string;
};

type PostAddressBody = {
  address: string;
  country?: string;
  state?: string;
  houseNumber?: string;
  landmark: string;
  zipcode?: string;
};

type PostAgentBody = {
  agentName: string;
  phone: string;
  countryCode: string;
};

type BlogRes = {
  blogsCount: number;
  blogs: [];
};

type NewsRes = {
  newsCount: number;
  news: [];
};

export type CountryRes = {
  uniqueCountries: string[];
};

export type AboutUsRes = {
  team: {
    Designation: string;
    name: string;
    _id: string;
    imagePath: string;
  }[];
};

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqList: builder.query<
      CommonResponseType & {
        data: { new: NotificationRes[]; old: NotificationRes[] };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.sellPost,
        method: "GET",
      }),
    }),
    getNotification: builder.query<
      CommonResponseType & {
        data: { new: NotificationRes[]; old: NotificationRes[] };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.notification,
        method: "GET",
      }),
    }),
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),
    postQuery: builder.mutation<CommonResponseType, PostContactUsBody>({
      query: (body) => ({
        url: END_POINTS.sellPost,
        method: "POST",
        body,
      }),
    }),
    getAllListedCountry: builder.query<
      CommonResponseType & { data: { uniqueCountries: string[] } },
      {}
    >({
      query: () => ({
        url: END_POINTS.sellPost,
        method: "GET",
      }),
    }),

    getRating: builder.query<
      CommonResponseType & { data: { rating: number } },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.sellPost}/${property_id}`,
        method: "GET",
      }),
    }),
    postRating: builder.mutation<
      CommonResponseType & { data: { rating: number } },
      { property_id: string; body: { rating: number } }
    >({
      query: ({ body, property_id }) => ({
        url: `${END_POINTS.sellPost}/${property_id}`,
        method: "PUT",
        body,
      }),
    }),

    postAddAddress: builder.mutation<CommonResponseType, PostAddressBody>({
      query: (body) => ({
        url: END_POINTS.address,
        method: "POST",
        body,
      }),
    }),

    getAllAddress: builder.query<
      CommonResponseType & {
        data: any;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.allAddress,
        method: "GET",
      }),
    }),

    deleteAddress: builder.mutation<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.address}/${id}`,
        method: "DELETE",
      }),
    }),

    getAddressById: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.address}/${id}`,
        method: "GET",
      }),
    }),

    updateAddress: builder.mutation<
      CommonResponseType & { data: any },
      { id: string; body: PostAddressBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.address}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    postAddAgent: builder.mutation<CommonResponseType, PostAgentBody>({
      query: (body) => ({
        url: END_POINTS.agent,
        method: "POST",
        body,
      }),
    }),

    getAllAgents: builder.query<
      CommonResponseType & {
        data: any;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.agent,
        method: "GET",
      }),
    }),

    getAgentsById: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.agent}/${id}`,
        method: "GET",
      }),
    }),

    updateAgents: builder.mutation<
      CommonResponseType & { data: any },
      { id: string; body: PostAgentBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.agent}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteAgent: builder.mutation<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.agent}/${id}`,
        method: "DELETE",
      }),
    }),

    getSettings: builder.query<
      CommonResponseType & {
        data: SettingRes;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.settings,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetFaqListQuery,
  useLazyGetCmsQuery,
  usePostQueryMutation,
  useLazyGetAllListedCountryQuery,
  usePostRatingMutation,
  useLazyGetRatingQuery,
  useLazyGetNotificationQuery,
  usePostAddAddressMutation,
  useLazyGetAllAddressQuery,
  useDeleteAddressMutation,
  useLazyGetAddressByIdQuery,
  useUpdateAddressMutation,
  usePostAddAgentMutation,
  useLazyGetAllAgentsQuery,
  useLazyGetAgentsByIdQuery,
  useUpdateAgentsMutation,
  useDeleteAgentMutation,
  useLazyGetSettingsQuery,
} = commonApi;
