import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../../types/General";

type props = {
  dashboardData: Dashboard | null;
};

const Banners = ({ dashboardData }: props) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section className="sliiick container">
      <Slider {...settings}>
        {dashboardData?.banner?.map((item) => (
          <div
            className="hm_bnr"
            key={item?._id}
            style={{
              backgroundImage: `url("/static/images/app_store.png")`,
            }}
          >
            <div
              className="mn_bnr"
              style={{
                backgroundImage: item?.image
                  ? `url(${item?.image})`
                  : `url("/static/images/facebook.png")`,
              }}
            >
              <div className="bnr_lft">
                <h1>
                  {/* New in{" "}
                  {item?.role === "SELL"
                    ? "sell"
                    : item?.role === "AUCTION"
                    ? "auction"
                    : "offer"} */}
                </h1>
                <h3>
                  {/* The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using ‘Content
                  here, content here’, making it look like readable English. */}
                </h3>
                <p></p>
                {/* <button onClick={() => navigate("/choose-category")}>
                  Join auction
                </button> */}
              </div>
              <div className="bnr_ryt">
                {/* <figure>
                  <img src="/static/images/bnr_img.png" alt="" />
                </figure> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Banners;
