import React from "react";
import Button from "./Button";
import { SeeBidders } from "../types/General";
import moment from "moment";

type props = {
  item: SeeBidders;
};

const BidderCard = ({ item }: props) => {
  return (
    <div className="bidder_main">
      <div className="bMLeft">
        <figure>
          <img src={item?.image || "/static/images/userDiv.png"} alt="" />
        </figure>
        <div className="bMRight">
          <h3>{item?.fullName || ""}</h3>
          <p>{moment(item?.createdAt).fromNow()}</p>
        </div>
      </div>
      <div className="bMryt">
        <Button value={`${item?.price} SAR`} />
      </div>
    </div>
  );
};

export default BidderCard;
