import React from "react";
import Layout from "../../layout";
import "./Cms.scss";
// import { usePostReviewMutation } from "../../service/Cms";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { generateEncryptedKeyBody } from "../../utils/crypto";
// import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";
import PhoneInput from "react-phone-input-2";
import { Button, InputField } from "../../components";
import { TextField } from "@mui/material";
import { useAppSelector } from "../../hooks/store";
import { getCms } from "../../reducers/generalSlice";
// import { isString } from "../../utils/validations";

const ContactUs = () => {
  const getCmsData = useAppSelector(getCms);
  // const [postReview] = usePostReviewMutation();

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     email: "",
  //     message: "",
  //   },
  //   validationSchema: Yup.object({
  //     name: Yup.string()
  //       .required("Name is required!")
  //       .min(3, "Minimum 3 characters are required"),
  //     email: Yup.string()
  //       .required("Email is required!")
  //       .matches(
  //         /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  //         "Enter a valid email address!"
  //       ),
  //     message: Yup.string()
  //       .required("Message is required!")
  //       .min(3, "Minimum 3 characters are required"),
  //   }),
  //   onSubmit: async (values) => {
  //     formik.setSubmitting(true);

  //     let data = {
  //       name: formik.values.name,
  //       email: formik.values.email,
  //       message: formik.values.message,
  //     };

  //     try {
  //       let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
  //       const response = await postReview(encryptedBody).unwrap();
  //       if (response?.statusCode === 200) {
  //         formik.resetForm();
  //         successToast("Query sent successfully");
  //       }
  //     } catch (error: any) {
  //       errorToast(error?.data?.message || "");
  //       console.log(error);
  //     }
  //   },
  // });

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="common_policy contact_us">
            <div className="container">
              <h1> Contact Us</h1>
              {/* <p>
            Lorem Ipsum has been the industry’s standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            <br /> scrambled it to make a type specimen book.
          </p> */}

              <div className="contact_frm">
                <h2> How can we help</h2>

                <div className="admin_dtls">
                  <p>
                    Email :
                    <a
                      href={getCmsData ? `mailto:${getCmsData?.email}` : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{getCmsData?.email || ""}</span>
                    </a>
                  </p>
                  <p>
                    Phone Number :
                    <a
                      href={
                        getCmsData
                          ? `tel:${getCmsData?.countryCode} ${getCmsData?.phone}`
                          : ""
                      }
                    >
                      <span>
                        <span> {getCmsData?.countryCode || ""}</span>{" "}
                        <span>{getCmsData?.phone}</span>
                      </span>
                    </a>
                  </p>
                </div>

                <form className="form_control">
                  <div className="conact_flx">
                    <div className="conact_one">
                      <InputField
                        placeholder="Name"
                        // name="name"
                        // id="name"
                        // value={formik.values.name}
                        // onChange={(val) => {
                        //   if (
                        //     val.target.value === " " ||
                        //     val.target.value === "."
                        //   ) {
                        //   } else if (isString(val.target.value)) {
                        //     formik.handleChange(val);
                        //   }
                        // }}
                        // onBlur={formik.handleBlur}
                        // helperText={formik.touched.name && formik.errors.name}
                      />
                    </div>
                    <div className="conact_one">
                      <InputField
                        placeholder="Email"
                        // name="email"
                        // id="email"
                        // value={formik.values.email}
                        // onChange={(val) => {
                        //   if (
                        //     val.target.value === " " ||
                        //     val.target.value === "."
                        //   ) {
                        //   } else {
                        //     formik.handleChange(val);
                        //   }
                        // }}
                        // onBlur={formik.handleBlur}
                        // helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: 10 }} />

                  <TextField
                    type="text"
                    className="text_field text_area"
                    hiddenLabel
                    variant="outlined"
                    placeholder="Message"
                    multiline
                    rows={4}
                    fullWidth
                    // name="message"
                    // id="message"
                    // inputProps={{ maxLength: 300 }}
                    // value={formik.values.message}
                    // onChange={(val) => {
                    //   if (val.target.value === " " || val.target.value === ".") {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // helperText={formik.touched.message && formik.errors.message}
                  />

                  <Button value="Contact Now" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
