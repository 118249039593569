import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { isNumber } from "../utils/validation";
import Button from "./Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import useTranslation from "../hooks/Translation";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tokenPrice: number;
  payTokenMoney: () => void;
  type?: string;
};

const data = [
  {
    id: 1,
    card_img: "/static/images/visa.png",
    card_number: "•••• •••• •••• 4432",
    description: "Personal - 06/23",
  },
];

const PayNowModal = ({
  open,
  setOpen,
  tokenPrice,
  payTokenMoney,
  type,
}: props) => {
  const navigate = useNavigate();
  const style = generateResponsiveStyle();
  const translation = useTranslation();

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="make_an_offer">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <div className="box">
              <h2
                style={{
                  marginTop: "-20px",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                {type === "addsell"
                  ? translation.posts.payTokenSell
                  : translation.posts.payTokenMoney}
              </h2>

              <div className="make_ofr">
                <TextField
                  disabled
                  className="ofr_inpt"
                  id="standard-basic"
                  placeholder="1000 SAR"
                  variant="standard"
                  value={tokenPrice}
                  inputProps={{ maxLength: 8 }}
                  // onChange={(event) => {
                  //   const inputValue = event.target.value;
                  //   if (isNumber(inputValue)) {
                  //     setOfferAmount(
                  //       inputValue === '' ? null : Number(inputValue)
                  //     );
                  //   }
                  // }}
                />
              </div>
              <ul className="cards_sec">
                {data?.map((item) => {
                  return (
                    <li className="mn_crds">
                      <div className="crd_dtls">
                        <figure>
                          <img src={item?.card_img} alt="" />
                        </figure>
                        <div className="dtl_mn">
                          <h4>{item?.card_number || ""}</h4>
                        </div>
                        <ArrowForwardIosIcon />
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="add_crd">
                <figure>
                  <img src="/static/images/add_card.svg" alt="" />
                </figure>
                <div className="add_details">
                  <h4>{translation.posts.addCard}</h4>
                </div>
              </div>
              <Button value="Pay Now" onClick={payTokenMoney} />
              <p className="refund"> {translation.posts.amountRefund}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PayNowModal;
