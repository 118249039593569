export const en = {
  Globals: {
    negotiate: "Negotiate",
    fixedPrice: "Fixed Price",
    fixedNego: "Fixed Price and negotiate",
    customPrice: "Custom Price",
    agree: "Agree",
    termsConditions: "Terms & Conditions",
    fixed: "Fixed",
    cancel: "Cancel",
    lastBid: "Last Bid",
    won: "Won",
    loose: "Loose",
    comment: "Comments",
    addComment: "Add a comment",
    enterPrice: "Enter Price",
    name: "Name",
    rating: "Rating",
    auction: "Auction",
    enable: "Enable",
    today: "Today",
    yesterday: "Yesterday",
    pending: "Pending",
    ongoing: "Ongoing",
    past: "Past",
    //
    Login: "Login",
    sign_up: "Sign Up",
    email: "Email",
    phone_number: "Phone Number",
    full_name: "Full Name",
    userName: "username",
    user_name: "User Name",
    address: "Address",
    submit: "Submit",
    profile: "Profile",
    chat: "Chat",
    wishlist: "Wishlist",
    notifications: "Notifications",
    logout: "Logout",
    categories: "Categories",
    see_all: "See All",
    select: "Select",
    no_info: "Information not found",
    no_faq: "No Faq listing found",
    message: "Message",
    price: "Price",
    apply: "Apply",
    no_recent_notifications: "No Recent Notifications found",
    older_notifications: "Older Notifications",
    no_older_notifications: "  No Older Notifications found",
    clear_notifications: "Clear All Notifications",
    all: "All",
    yes: "Yes",
    no: "No",
    save: "Save",
    back: "Back",
    update: "Update",
    add: "Add",
    location: "Location",
    edit_detail: "Edit Detail",
    for: "For",
    expiry_date: "Expiry Date",
    delete_err: "Are you sure to delete this",
    invalidImg: "Invalid file type. Please upload only images.",
  },
  Auth: {
    Login: {
      remember: "Remember me",
      forgot_pas: "Forgot Password?",
      email_id: "Email Id",
      password: "Password",
      logintoLowhate: "Log in to Lowhate",
      signup: "SignUp",
      not_member: "Not a member",
      sign_up_with_email: "Login with Email",
      sign_up_with_phone: "Login with Phone number",
    },
    Sign_up: {
      register: "Register",
      welcome: "Welcome to Lowhate",
      confirm_password: "Confirm Password",
      sign_up_with: "Sign up With",
      countryCode: "Country Code",
      selectCountry: "Select Country",
      alreadyHaveAcc: "Already have an account?",
    },
    forgot_password: {
      enter_email: "Enter your email here",
    },
    otp_verification: {
      verification: "Verification",
      email_code: "We have sent an OTP code to your email",
      phone_code: "We have just sent you a verification code via",
      resend: "Resend",
      code_expire: "The OTP code will expire in",
      enterOtp: "Enter Your Otp",
      otp: "Enter OTP",
      dontGetOtp: "Didn't get the code?",
      sendOtp: "Send Otp",
    },
    profile_setup: {
      setup: "Profile Setup",
    },
    reset_password: {
      reset: "Reset Password",
      new_password: "New Password",
    },
    change_password: {
      change: "Change Password",
      old_pass: "Old Password",
      new_pass: "New Password",
    },
  },
  header: {
    home: "Home",
    services: "Services",
    bids: "My Bids",
    sell: "Sell",
    login: "Login",
    search: "Search",
    postSell: "Post for sell",
    postAuction: "Post for Auction",
    postOffer: "Post for Offer",
    arabic: "Arabic",
    profile: "Profile",
    chat: "Chat",
    notifications: "Notifications",
    logout: "Logout",
    logoutWarning: "Are you sure you want to logout?",
    yes: "Yes",
    no: "No",
  },

  footer: {
    support: "Support",
    contact_us: "Contact Us",
    faq: "FAQ",
    about_us: "About Us",
    privacy: "Privacy Policy",
    terms: "Terms & Conditions",
    follow_us: "Follow Us",
    subscribe: "Subscribe",
    enter_email: "Enter your email",
    lowhate: "Lowhate",
    rights_reserved: "All Rights Reserved",
    categories: "Categories",
    pickupCar: "Public Cars",
    publicCar: "Pick-up cars",
    side_text:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  },
  home: {
    download: "Downlaod The Lowhate App",
    buySell:
      "Buy or Sell anything using the app on your mobile. Find Jobs, Homes, Services and more.",
    binary: "Binary",
    auctionPost: "Auction Posts",
    offerPost: "Offer Posts",
    sellPosts: "Sell Posts",
    latesPost: "Latest Ads",
    mostLiked: "Most Liked Post",
    quadruple: "Quadruple",
    triple: "Triple",
    oddNumber: "Odd Numbers",
    seeAll: "See All",
    searchText:
      " Write the letters of the board in arabic consisting of three letters",
    seearchText1: "Write the plate number consisting of the 4 digit",
    result: "Results",
    notifyMe: "Notify Me",
    notifyMsg: "Notify when number plate matching",
    available: "available?",
    soryBy: "Sort By",
    filters: "Filters",
    priceRange: "Price Range",
    category: "Category",
    clearFilter: "Clear filters",
  },
  error: {
    noData: "No Data Found",
    noBidders: "No Bidders Yet",
    noservice: "No Service Found",
    noAgent: "No Agents Found",
  },
  profile: {
    new_card: "Add new card",
    pay_via: "Save and Pay via Cards.",
    enter_details: "Enter your card details.",
    holder_name: "Card holder name",
    card_number: "Card Number",
    cvv: "CVV",
    save_card: "Save this card for future payments?",
    add_address: "Add New Address",
    edit_address: "Edit address",
    house: "House / Flat / Block No.",
    landmark: "Landmark",
    save_As: "Save As",
    home: "Home",
    work: "Work",
    other: "Other",
    edit: "Edit",
    no_address: "No Address Found",
    delete_address: " Are you sure you want to delete this address?",
    manage_profile: "Manage your profile details",
    invalid_email: "Invalid Email",
    verify: "Verify",
    invalid_number: "Invalid Number",
    expiry_date: "Expiry Date",
    description: "Description",
    paymentHistory: "Payment History",
    Weekly: "Weekly",
    Monthly: "Monthly",
    users: "users",
    myListing: "My Listed post",
    myAuctionPost: "My Auction post",
    manageAgent: "Manage Agents",
    manageAddress: "Manage Adresses",
    managePayment: "Manage Payment",
    manageReview: "Manage Reviews",
    myEarning: "My Earnings",
    myWishlist: "My Wishlist",
  },
  chat: {
    no_chats: "No Chats Found",
    go_ahead: "Go Ahead and start conversation",
    type_message: "Type a message",
    sharedMedia: "Shared media file",
    selectImgVdo: "Please select images and videos to upload",
  },

  validations: {
    required_field: "This field is required",
    email_required: "Email is required!",
    valid_email: "Enter a valid email address!",
    password_required: "Password is required!",
    password_validation:
      "Must contain 8 or more characters, one uppercase, one lowercase and one number.",
    password_match: "Passwords must match.",
    cofirmPass_required: "Confirm password is required.",
    images_allowed: "Only images are allowed",
    min_three: "Minimum 3 characters are required",
    min_two: "Minimum 2 characters are required",
    min_nine: "Minimum 9 characters are required",
    max_fifteen: "Maximum 15 character are allowed",
    max_Eighty: "Maximum 80 character are allowed",
    old_pass_required: "Old password is required.",
    new_pass_required: "New password is required.",
    new_pass_validation: "New password cannot be same as old password.",
    card_holder_name: "Card holder name must be at least 3 characters",
    phone_min: "Phone number must be at least 6 characters",
    phone_max: "Phone number must be utmost 16 characters",
    cvv_val: "CVV must be atleast 3 characters",
    cardNo_val: "Card number must be at least 16 characters",
  },
  toast_messages: {
    setup_complete: "Congratulations! Your profile setup is complete",
    password_updated: "Password is updated successfully",
    try_again: "Please try again",
    address_deleted: "Address deleted successfully",
    address_added: "Address Added Successfully",
    address_updated: "Address Updated Successfully",
    postUpdated: "Post Updated Successfully",
    postAdded: "Post Added Successfully",
    removePost: "We have removed your post",
    postdeleted: "Post deleted successfully",
    thankyouMsg: "Thankyou for using Lowhate",
    commentAdded: "Comment Added Successfully",
    selectAgent: "Select Agent first",
    delete_agent: "Agent deleted successfully",
    agent_add: "Agent Added Successfully",
    agent_update: "Agent Updated Successfully",
    otpResend: "Otp resend successfully",
    subscriptionAdd: "Subscription buy successfully",
    profile_updated: "Profile Updated Successfully",
  },
  posts: {
    sellPostUpdate: "Update Post For Sell",
    sellPostAdded: "Add Post For Sell",
    postTitle: "Post title",
    carPlateNum: "Car plate number",
    meaningOfPlate: "Meaning of Car Plate (Optional)",
    serviceAgent: "Service and Agents",
    disclaimer:
      "Title, Car plate number and Meaning of car plate can not be changed later.",
    paySubmit: "Pay & Submit",
    auctionPostUpdate: "Update Post For Auction",
    auctionPostAdd: "Add Post For Auction",
    pricePlate: "Price of the Plate",
    startedBid: "Starting Bid",
    bidStartDate: "Bidding Start Date",
    offerPostUpdate: "Update Post For Offer",
    offerPostAdd: "Add Post For Offer",
    carPlateDetail: "Car Plate Details",
    postedOn: "Posted on",
    startedFrom: "Starting From",
    auctionStartDate: "Auction Date :",
    endAuction: "Ending Auction Time",
    status: "Status :",
    statusofplate: "Status of Car Plate",
    amountPaid: "Amount paid",
    serviceSeller: "Services Provided By Seller",
    viewOffer: "View offers",
    seeBidder: "See All Bidders",
    sellerDetail: "Seller Detail",
    chatNow: "Chat Now",
    likesComment: "Likes & Comments",
    reply: "Reply",
    writeReply: "Write a reply",
    startBidding: "Start Bidding",
    viewDetails: "View Details",
    payTokenMoney: "Pay Token money for bid",
    payTokenSell: "Pay Token money for Sell Post",
    addCard: "Add new card",
    amountRefund: "This amount will be refundable",
    startingFrom: "Starting from",
    closePost: "Close Post",
    alreadyClosed: "Already Closed",
    advertisePost: "Advertise Post",
  },
  bids: {
    myBids: "My Bids",
    bidDetail: "Bid Detail",
    bidders: "Bidders",
    currentBid: "Current bid",
    bidOutBid: "You've been outbid",
    increaseMaxBid: "Increase max bid",
    placeBid: "Place a bid",
    weBid: "We'll bid for you, up to",
    disclaimer: "By bidding, you agree to pay the seller if you win.",
    placeBid1: "Place bid",
    orhigher: "or higher",
    addOffer: "Add offer price",
    madeOffer: "You make made an offer of 45 SAR",
    addOffer1: "Add Offer",
    anonymousoffer: "Anonymous offered",
  },
  service: {
    addPrice: "Add Price For Service",
    addService: "Add Service",
    addAgent: "Add Agent",
    chooseAgent: "Choose Agents",
    addNewAgent: "Add New Agent",
    agentName: "Agent Name",
    agent: "Agent",
  },
  subscriptions: {
    subscription: "Subscription",
    skip: "Skip",
    month: "Month",
    months: "Months",
    year: "Year",
    for: "for",
  },
  auctionTeaser: "Auction Teaser",
  okay: "Okay",
  enterValidDate: "Please enter valid dates",
  auctionTimes: "Auction Timings :",
  sold: "Sold",
  unsold: "Un Sold",
  whyclose: "Why you are closing this post",
  closePostText: "Your post will be closed in",
};
