import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./Notifications.scss";
import { useLazyGetNotificationQuery } from "../../service/Common";
import { Loader, errorToast } from "../../helpers";
import { NotificationRes } from "../../types/General";
import moment from "moment";
import useTranslation from "../../hooks/Translation";

const Notifications = () => {
  const translation = useTranslation();

  const [notification, { isLoading }] = useLazyGetNotificationQuery();
  const [oldNotification, setOldNotification] = useState<NotificationRes[]>([]);
  const [newNotification, setNewNotification] = useState<NotificationRes[]>([]);

  const getNotification = async () => {
    try {
      const response = await notification({}).unwrap();
      if (response?.statusCode === 200) {
        setNewNotification(response?.data?.new || []);
        setOldNotification(response?.data?.old || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getNotification();
  }, []);
  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls  notification_pg">
        <div className="comment_card">
          <div className="container">
            {newNotification?.length ? (
              <>
                <p>{translation.Globals.today}</p>
                {newNotification?.map((item) => (
                  <div
                    className="rcnt_notification old_notification"
                    key={item?._id}
                  >
                    <figure>
                      <img src="/static/images/notification3.png" alt="" />
                    </figure>
                    <div className="title">
                      <h6>{item?.message}</h6>
                      <p>{moment(item?.createdAt).format("LT")}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : null}

            {oldNotification?.length ? (
              <>
                <p>{translation.Globals.yesterday}</p>
                {oldNotification?.map((item) => (
                  <div
                    className="rcnt_notification old_notification"
                    key={item?._id}
                  >
                    <figure>
                      <img src="/static/images/notification3.png" alt="" />
                    </figure>
                    <div className="title">
                      <h6>{item?.message}</h6>
                      <p>{moment(item?.createdAt).format("LT")}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
