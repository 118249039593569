import { Box, Modal, TextField, Typography, Checkbox } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import { isNumber } from "../utils/validation";
import Button from "./Button";
import { useLazyGetAllServicesQuery } from "../service/home";
import { Loader, errorToast } from "../helpers";
import { AllServiceType } from "../types/General";
import { useAddMyServiceMutation } from "../service/posts";
import { useNavigate } from "react-router-dom";
import useTranslation from "../hooks/Translation";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  servicePrice: string;
  setServicePrice: Dispatch<SetStateAction<string>>;
  handleSubmit: () => void;
  selectedId: AllServiceType | null;
  setSelectedId: Dispatch<SetStateAction<AllServiceType | null>>;
};

const AddServiceModal = ({
  open,
  setOpen,
  handleSubmit,
  servicePrice,
  setServicePrice,
  selectedId,
  setSelectedId,
}: props) => {
  const style = generateResponsiveStyle();
  const translation = useTranslation();

  const [allService, { isLoading }] = useLazyGetAllServicesQuery();
  const [addService, addServiceData] = useAddMyServiceMutation();

  const [services, allServices] = useState<AllServiceType[]>([]);

  const getAllServices = async () => {
    try {
      const response = await allService({}).unwrap();
      if (response?.statusCode === 200) {
        allServices(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (selectedId) {
      const selectedService = services.find(
        (service) => service._id === selectedId?._id
      );
      if (selectedService && selectedService?.serviceFees) {
        setServicePrice(String(selectedService?.serviceFees));
      } else {
        setServicePrice("0"); // Set an empty string if service fees are not available
      }
    }
  }, [selectedId, services]);

  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading || addServiceData?.isLoading} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ padding: "16px", maxHeight: 470, overflow: "scroll" }}
        >
          <div className="service">
            <ul>
              {services?.length
                ? services?.map((item: AllServiceType) => (
                    <li>
                      <div className="serviceLabel">
                        <h3>{item?.serviceType || ""}</h3>
                        <Checkbox
                          onClick={() => setSelectedId(item)}
                          checked={selectedId?._id === item?._id}
                        />
                      </div>
                      {selectedId?._id === item?._id ? (
                        <div className="priceInput">
                          <TextField
                            placeholder={translation.Globals.enterPrice}
                            className="price_Input"
                            autoFocus
                            id="name"
                            name="email"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={servicePrice}
                            onChange={(val) => {
                              if (
                                val.target.value === " " ||
                                val.target.value === "."
                              ) {
                              } else if (isNumber(val.target.value)) {
                                setServicePrice(val.target.value);
                              }
                            }}
                          />
                        </div>
                      ) : null}
                    </li>
                  ))
                : null}
            </ul>
          </div>
          <div className="btn-payment" style={{ marginTop: 30 }}>
            <Button value={translation.Globals.submit} onClick={handleSubmit} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddServiceModal;
