import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { Button } from "../../components";
import useTranslation from "../../hooks/Translation";

const ManagePayments = () => {
  const translation = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const style = generateResponsiveStyle();

  const data = [
    {
      id: 1,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 2,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
  ];
  return (
    <div className="main">
      <ul className="cards_sec">
        {data?.map((item) => {
          return (
            <li className="mn_crds">
              <div className="crd_dtls">
                <figure>
                  <img src={item?.card_img} alt="" />
                </figure>
                <div className="dtl_mn">
                  <h4>{item?.card_number || ""}</h4>
                  <p>{item?.description}</p>
                </div>
              </div>
              <figure className="delete_dv">
                <img src="/static/images/delete.png" alt="" />
              </figure>
            </li>
          );
        })}
      </ul>
      <div className="add_crd" onClick={() => setOpen(true)}>
        <figure>
          <img src="/static/images/add_card.svg" alt="" />
        </figure>
        <div className="add_details">
          <h4>{translation.posts.addCard}</h4>
          <p>{translation.profile.pay_via}</p>
          <div className="paymnt_cards">
            <figure>
              <img src="/static/images/visa.svg" />
            </figure>
            <figure>
              <img src="/static/images/master-card.svg" />
            </figure>
            <figure>
              <img src="/static/images/discover.svg" />
            </figure>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="AuthWrap  payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <h2>{translation.profile.new_card}</h2>
            <p>{translation.profile.enter_details}</p>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className="custom_label2">
                  {translation.profile.holder_name}
                </Typography>
                <TextField
                  fullWidth
                  id="standard-basic"
                  placeholder={translation.profile.holder_name}
                  variant="standard"
                  className="paymnt_modl_txtfld"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className="custom_label2">
                  {translation.profile.card_number}
                </Typography>
                <TextField
                  fullWidth
                  id="standard-basic"
                  placeholder={translation.profile.card_number}
                  variant="standard"
                  className="paymnt_modl_txtfld"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <figure>
                          <img src="/static/images/visa.png" alt="" />
                        </figure>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingLeft: "16px",
                  paddingTop: "16px",
                }}
              >
                <Grid item xs={4}>
                  <Typography variant="h6" className="custom_label2">
                    {translation.profile.expiry_date}
                  </Typography>
                  <TextField
                    className="paymnt_modl_txtfld"
                    variant="standard"
                    placeholder="MM/YYYY"
                    inputProps={{ maxLength: 7 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className="custom_label2">
                    {translation.profile.cvv}
                  </Typography>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    placeholder={translation.profile.cvv}
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <figure className="cvv_img">
                            <img src="/static/images/cvv.svg" alt="" />
                          </figure>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Box>
              <Grid item xs={12} className="sv_crd">
                <FormControlLabel
                  control={<Checkbox />}
                  // checked={formik.values.remember}
                  name="remember"
                  // onChange={formik.handleChange}
                  label={translation.profile.save_card}
                />
              </Grid>
              <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                <Button
                  value={translation.Globals.save}
                  onClick={() => setOpen(false)}
                />
              </Box>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ManagePayments;
