import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Checkbox } from "@mui/material";
import { AddServiceModal, Button } from "../../components";
import { Loader, errorToast } from "../../helpers";
import { AllServiceType, SellService } from "../../types/General";
import {
  useAddMyServiceMutation,
  useLazySellServiceListQuery,
} from "../../service/posts";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

const Services = () => {
  const navigate = useNavigate();
  const translation = useTranslation();

  const [addServiceClick, serAddServiceClick] = useState(false);
  const [servicePrice, setServicePrice] = useState("");
  const [services, allServices] = useState<SellService[]>([]);
  const [selectedId, setSelectedId] = useState<AllServiceType | null>(null);

  const [allMyServiceList, { isLoading }] = useLazySellServiceListQuery();
  const [addService, addServiceData] = useAddMyServiceMutation();

  const getMyServices = async () => {
    try {
      const response = await allMyServiceList({}).unwrap();
      if (response?.statusCode === 200) {
        allServices(response?.data?.service || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const addMyService = async () => {
    if (servicePrice === "0") {
      errorToast(translation.service.addPrice);
      return;
    }
    const body = {
      serviceId: selectedId?._id,
      serviceFees: Number(servicePrice),
    };
    if (selectedId && servicePrice !== "0") {
      try {
        const response = await addService(body).unwrap();
        if (response?.statusCode === 200) {
          serAddServiceClick(false);
          getMyServices();
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    }
  };

  useEffect(() => {
    getMyServices();
  }, []);

  return (
    <Layout>
      <Loader isLoad={addServiceData?.isLoading || isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="flexJus">
              <h1 className="prdct_dtl_hdng">{translation.header.services}</h1>
              <div className="service_btn">
                <Button
                  value={translation.service.addService}
                  onClick={() => serAddServiceClick(true)}
                />
              </div>
            </div>
            <div className="serviceList">
              <ul>
                {services?.length ? (
                  services?.map((item) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/service-agents/${item?._id}`, {
                          state: item,
                        })
                      }
                    >
                      <h3>{item?.serviceType || ""}</h3>
                      <div className="greenDotService" />
                    </li>
                  ))
                ) : (
                  <div className="noData">
                    <figure>
                      <img src="/static/images/noData.webp" alt="" />
                    </figure>
                    <h3> {translation.error.noservice}</h3>
                  </div>
                )}
              </ul>
            </div>
            {/* <div className="btn-payment">
              <Button value="Request for a service" />
            </div> */}
          </div>
        </div>
      </div>
      <AddServiceModal
        open={addServiceClick}
        setOpen={serAddServiceClick}
        handleSubmit={addMyService}
        setServicePrice={setServicePrice}
        servicePrice={servicePrice}
        setSelectedId={setSelectedId}
        selectedId={selectedId}
      />
    </Layout>
  );
};

export default Services;
