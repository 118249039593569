import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import useTranslation from "../hooks/Translation";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  name: string;
};

const WarnModal = ({ open, setOpen, handleDelete, name }: props) => {
  const style = generateResponsiveStyle();
  const translation = useTranslation();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "16px" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px" }}
          >
            {translation.Globals.delete_err} {name}?
          </Typography>
          <div className="flexDiv">
            <Button
              className="yesBtn"
              onClick={() => {
                setOpen(false);
                handleDelete();
              }}
            >
              {translation.Globals.yes}
            </Button>

            <Button
              sx={{ color: "#204e33", border: "1px solid #204e33" }}
              onClick={() => setOpen(false)}
            >
              {translation.Globals.no}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default WarnModal;
