import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, InputField } from "../../components";
import PhoneInput from "react-phone-input-2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Input, Menu, MenuItem, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import { ChatHistoryResponse } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { UploadMedia } from "../../utils/uploadMedia";
import { errorToast } from "../../helpers";
import { SOCKET_KEYS } from "../../helpers/storage";
import useTranslation from "../../hooks/Translation";

type props = {
  chatHistory: ChatHistoryResponse[];
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  media: string;
  setMedia: Dispatch<SetStateAction<string>>;
  messageType: string;
  setMessageType: Dispatch<SetStateAction<string>>;
  selectedReceiverName: string;
  selectedReceiverImage: string;
  sendMessage: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const ChatDetail = ({
  chatHistory,
  setText,
  text,
  selectedReceiverImage,
  selectedReceiverName,
  sendMessage,
  setMedia,
  media,
  setMessageType,
  messageType,
  loading,
  setLoading,
}: props) => {
  const userData = useAuth();
  const translation = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;

  const handleMessageInput = (e: any) => {
    setText(e.target.value);
  };

  const keyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  // Group messages by date
  const messagesByDate: { [date: string]: ChatHistoryResponse[] } = {};
  chatHistory
    .slice()
    .reverse()
    ?.forEach((message) => {
      const date = new Date(message.createdAt).toLocaleDateString();
      if (!messagesByDate[date]) {
        messagesByDate[date] = [];
      }
      messagesByDate[date].push(message);
    });

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      event.target.value = "";
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setMedia(res?.data);
          setMessageType(SOCKET_KEYS.image);
          setLoading(false);
        } else {
          setLoading(false);
          errorToast(res?.message);
        }
      } else if (file.type.startsWith("video/")) {
        setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setMedia(res?.data);
          setMessageType(SOCKET_KEYS.video);
          setLoading(false);
        } else {
          errorToast(res?.message);
          setLoading(false);
        }
      } else {
        errorToast(translation.chat.selectImgVdo);
        setLoading(false);
      }
    }
  };

  return (
    <div className="main">
      <div className="heading">
        <div className="userDetail">
          <figure>
            <img
              style={{ borderRadius: "100%", objectFit: "cover" }}
              src={selectedReceiverImage || "/static/images/userDiv.png"}
              alt=""
            />
          </figure>
          <div className="username">
            <h3>{selectedReceiverName || ""}</h3>
          </div>
        </div>
      </div>

      {/* chat detail */}

      <div className="chat-div">
        {Object.entries(messagesByDate)?.map(([date, messages]) => (
          <>
            <div key={date}>
              <div className="time">
                <p>{date}</p>
              </div>

              {messages?.length
                ? messages.map((item) => (
                    <>
                      {item?.type === "TEXT" ? (
                        <div
                          className={
                            item?.senderId === userData?._id
                              ? "out-going"
                              : "incoming"
                          }
                          key={item?._id} // Make sure to add a unique key for each item
                        >
                          <p>{item?.text || ""}</p>
                        </div>
                      ) : item?.type === "IMAGE" && item?.text ? (
                        <div
                          className={
                            item?.senderId === userData?._id
                              ? "out-going"
                              : "incoming"
                          }
                          key={item?._id}
                        >
                          <figure>
                            <img src={item?.text} />
                          </figure>
                        </div>
                      ) : item?.type === "VIDEO" && item?.text ? (
                        <div
                          className={
                            item?.senderId === userData?._id
                              ? "out-going"
                              : "incoming"
                          }
                          key={item?._id}
                        >
                          <video
                            style={{ cursor: "pointer" }}
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="hm_vd"
                            preload="yes"
                            // onClick={() =>
                            //   handlePopup(item?.link, item?.type)
                            // }
                          >
                            <source
                              src={item?.text
                                .split(":::")
                                .find((link) => link.trim().endsWith(".mp4"))}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : null}
                    </>
                  ))
                : null}
            </div>
          </>
        ))}
        <div id="scrollTo" />
      </div>
      {/* chat detail */}

      {/* chat footer */}

      <div className="chat_foot">
        <label htmlFor="icon-button-file">
          <Input
            sx={{ display: "none" }}
            id="icon-button-file"
            type="file"
            inputProps={{
              accept: ".png, .jpg, .jpeg, .svg, .mp4", // Add video formats here
            }}
            onChange={handleImageUpload}
          />

          <AddIcon className="addIcon" />
        </label>
        {media ? (
          <div className="mediaContainer">
            {messageType === SOCKET_KEYS.image ? (
              <figure>
                <img src={media} />
              </figure>
            ) : (
              <video
                style={{ cursor: "pointer" }}
                autoPlay
                muted
                loop
                playsInline
                className="hm_vd"
                preload="yes"
              >
                <source src={media} type="video/mp4" />
              </video>
            )}
          </div>
        ) : (
          <TextField
            hiddenLabel
            placeholder={translation.chat.type_message}
            fullWidth
            name="text"
            type="message"
            variant="outlined"
            multiline
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                handleMessageInput(val);
              }
            }}
            value={text}
            onKeyDown={(e) => {
              if (e.key === "Enter" && text.trim() !== "") {
                keyDownHandler(e);
              }
            }}
          />
        )}

        <SendIcon
          onClick={() => {
            // if (text !== "") {
            sendMessage();
            // }
          }}
        />
      </div>
      {/* chat footer */}
    </div>
  );
};

export default ChatDetail;
