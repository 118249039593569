import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
} from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import OTPInput from "react-otp-input";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { ModalType, toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "../../helpers";

type Props = {
  closeModal: () => void;
  phone: boolean;
  signUp: boolean;
};

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBotton: 20,
};

const Verification = ({ closeModal, phone, signUp }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = () => {
    closeModal();
    if (phone && !signUp) {
      navigate("/");
    } else if (signUp) {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "profileSetup" })
      );
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "reset" })
      );
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross">
          <CloseIcon />
        </div>
        <h2>Verification</h2>

        {phone ? (
          <p>
            We have sent you SMS with a code to phone number{" "}
            <span>+91 84984-65828</span>
          </p>
        ) : (
          <p>
            We have sent you an SMS with a code to email{" "}
            <span>admin@apptunix.com</span>
          </p>
        )}

        {/* <InputField placeholder="Email Id" />
         */}
        <FormControl className="opt_fields" sx={{ width: "100%" }}>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputStyle={otpStyle}
            inputType="tel"
          />
          <br />
          {error && otp.length !== 4 ? (
            <h6 className="err_msg">This field is required</h6>
          ) : (
            ""
          )}
        </FormControl>

        <Button value="Next" onClick={handleSubmit} />
        <div className="continue">
          <h5>The verify code will expire in 00 : {countDown}</h5>
        </div>
        <div className="resend">
          <h4>Resend Code</h4>
        </div>
      </div>
    </div>
  );
};

export default Verification;
