import { Box, Modal, TextField, Typography, Checkbox } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import { isNumber } from "../utils/validation";
import Button from "./Button";
import { useLazyGetAllServicesQuery } from "../service/home";
import { Loader, errorToast } from "../helpers";
import { AllServiceType, SellService } from "../types/General";
import {
  useAddAgentToServiceMutation,
  useAddMyServiceMutation,
} from "../service/posts";
import { useNavigate } from "react-router-dom";
import { useLazyGetAllAgentsQuery } from "../service/Common";
import useTranslation from "../hooks/Translation";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  setServiceId: Dispatch<SetStateAction<string[]>>;
  serviceId: string[];
  serviceData: SellService | null;
};

const AddAgentModal = ({
  open,
  setOpen,
  handleSubmit,
  serviceId,
  setServiceId,
  serviceData,
}: props) => {
  const style = generateResponsiveStyle();
  const translation = useTranslation();

  const [allAgentsMutation, { isLoading }] = useLazyGetAllAgentsQuery();

  const [agents, setAllAgents] = useState<any[]>([]);

  const getAllAgents = async () => {
    try {
      const response = await allAgentsMutation({}).unwrap();
      if (response?.statusCode === 200) {
        const filteredAgentIds =
          serviceData?.userserviceagents?.map((item) => item?.agentId?._id) ||
          [];
        const filteredAgents = response?.data?.user?.filter(
          (agent: any) => !filteredAgentIds?.includes(agent?._id)
        );
        setAllAgents(filteredAgents);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllAgents();
  }, [serviceData]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ padding: "16px", maxHeight: 470, overflow: "scroll" }}
        >
          <h2 style={{ paddingBottom: 15 }}>
            {translation.service.chooseAgent}
          </h2>
          <div className="service">
            <ul>
              {agents?.length
                ? agents?.map((item: any) => (
                    <li>
                      <div className="serviceLabel">
                        <h3>{item?.agentName || ""}</h3>
                        <Checkbox
                          onClick={(e) => {
                            if (serviceId.includes(item?._id)) {
                              setServiceId((serviceId) =>
                                serviceId.filter((s) => s !== item?._id)
                              );
                            } else {
                              setServiceId((serviceId) => [
                                ...serviceId,
                                item?._id,
                              ]);
                            }
                          }}
                          checked={serviceId?.includes(item?._id)}
                        />
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          </div>
          <div className="btn-payment" style={{ marginTop: 30 }}>
            <Button value={translation.Globals.submit} onClick={handleSubmit} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddAgentModal;
