import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../layout";
import { Button } from "@mui/material";
import "../profile/profile.scss";
import { ChatDetail, EditProfile } from "../../features";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../helpers";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  useLazyGetChatHistoryQuery,
  useLazyGetChatListQuery,
} from "../../service/bidding";
import { ChatHistoryResponse, ChatResponse } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { socket } from "../../utils/socket";
import { SOCKET_KEYS } from "../../helpers/storage";
import useTranslation from "../../hooks/Translation";

const Chat = () => {
  let isEventListenerAttached = false;

  const translation = useTranslation();
  const userData = useAuth();
  const location = useLocation();
  const { state } = location;

  const [getChatList, { isLoading }] = useLazyGetChatListQuery();
  const [getChatHistoryMethod, getChatHistoryData] =
    useLazyGetChatHistoryQuery();

  const [chats, setChats] = useState<ChatResponse[]>([]);
  const [chatHistory, setChatHistory] = useState<ChatHistoryResponse[]>([]);
  console.log("chatHistory: ", chatHistory);
  const [selectedReceiverName, setSelectedReceiverName] = useState("");
  const [selectedReceiverImage, setSelectedReceiverImage] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [selectedReceiverId, setSelectedReceiverId] = useState("");
  console.log(
    "selectedReceiverId: ",
    selectedRoomId,
    selectedReceiverId,
    selectedReceiverImage,
    selectedReceiverName
  );
  const [text, setText] = useState("");
  const [media, setMedia] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    setTimeout(() => {
      const ele = document.getElementById("scrollTo");
      ele?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 100);
  };

  const getChatListApi = async () => {
    try {
      const response = await getChatList({}).unwrap();

      if (response?.statusCode === 200) {
        setChats(response?.data?.chats || []);
        scrollToBottom();
      }
    } catch (error: any) {
      console.log("error: ", error);
    }
  };

  const getChatHistory = async (id: string) => {
    try {
      const response = await getChatHistoryMethod({
        connectionId: id,
      }).unwrap();

      if (response?.statusCode === 200) {
        setChatHistory(response?.data || []);
      }
    } catch (error: any) {
      console.log("error: ", error);
    }
  };

  const handleChatClick = (item: ChatResponse) => {
    getChatHistory(item?.connectionId);
    setSelectedReceiverImage(item?.image);
    setSelectedReceiverName(item?.fullName);
    setSelectedRoomId(item?.connectionId);
    setSelectedReceiverId(item?.userId);
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats, chatHistory]);

  const sendMessage = async () => {
    const body = {
      senderId: userData?._id,
      recieverId: selectedReceiverId,
      connectionId: selectedRoomId,
      type: text
        ? SOCKET_KEYS.text
        : !text && messageType === SOCKET_KEYS.image
        ? SOCKET_KEYS.image
        : SOCKET_KEYS.video,
      message: text ? text : media,
    };
    console.log(body, "sendmessage");

    const data = {
      senderId: userData?._id,
      text: text ? text : media,
      type: text
        ? SOCKET_KEYS.text
        : !text && messageType === SOCKET_KEYS.image
        ? SOCKET_KEYS.image
        : SOCKET_KEYS.video,
      createdAt: new Date().toISOString(),
    } as ChatHistoryResponse;

    if (socket) {
      socket?.emit("sendMessage", body);
      if (body) {
        console.log("body: ", body, "...chatHistory");
        setChatHistory([data, ...chatHistory]);
        console.log("body: ", body, "2...chatHistory");

        setText("");
        setMedia("");
        setMessageType("");
        scrollToBottom();
      }
    }
  };

  // const sendMessage = async () => {
  //   const body = {
  //     senderId: userData?._id,
  //     recieverId: selectedReceiverId,
  //     connectionId: selectedRoomId,
  //     type: text
  //       ? SOCKET_KEYS.text
  //       : !text && messageType === SOCKET_KEYS.image
  //       ? SOCKET_KEYS.image
  //       : SOCKET_KEYS.video,
  //     message: text ? text : media,
  //   };
  //   console.log(body, "sendmessage");

  //   if (socket) {
  //     socket.emit("sendMessage", body, (response: any) => {
  //       // If the message was sent successfully, add it to chatHistory
  //       if (response.success) {
  //         const data = {
  //           senderId: userData?._id,
  //           text: text,
  //           type: text
  //             ? SOCKET_KEYS.text
  //             : !text && messageType === SOCKET_KEYS.image
  //             ? SOCKET_KEYS.image
  //             : SOCKET_KEYS.video,
  //           createdAt: new Date().toISOString(),
  //         } as ChatHistoryResponse;
  //         setChatHistory([data, ...chatHistory]);
  //         setText("");
  //         setMedia("");
  //         setMessageType("");
  //         scrollToBottom();
  //         console.log("done-------");
  //       } else {
  //         // Handle error if the message couldn't be sent
  //         console.error("Failed to send message:", response.error);
  //         // You might want to show an error message to the user
  //       }
  //     });
  //   }
  // };

  // const recieveChat = async () => {
  //   if (socket) {
  //     console.log("socket: hai", socket);
  //     socket.on("receiveMessage", async (data: any) => {
  //       console.log("rood-----------------------", data);
  //       if (data?.senderId === userData?._id) {
  //         console.log(" null case  send message==================");
  //       } else {
  //         const body = {
  //           senderId: data?.senderId,
  //           text: data?.text,
  //           type: data?.type,
  //           _id: data?._id,
  //           recieverId: data?.recieverId,
  //           createdAt: data?.createdAt,
  //           receiverDetail: data?.receiverDetail,
  //           senderDetail: data?.senderDetail,
  //         } as ChatHistoryResponse;
  //         setChatHistory((history) => [body, ...history]);
  //         scrollToBottom();
  //       }
  //     });
  //   }
  // };

  const recieveChat = async () => {
    console.log("recieveChat: ", recieveChat);
    if (socket && !isEventListenerAttached) {
      isEventListenerAttached = true; // Set flag to indicate event listener is attached
      socket.on("receiveMessage", async (data: ChatHistoryResponse) => {
        console.log("rood-----------------------", data);
        if (data?.senderId === userData?._id) {
          console.log("null case send message==================");
        } else {
          console.log("apend in receive");
          const body = {
            senderId: data?.senderId,
            text: data?.text,
            type: data?.type,
            _id: data?._id,
            recieverId: data?.recieverId,
            createdAt: data?.createdAt,
            receiverDetail: data?.receiverDetail,
            senderDetail: data?.senderDetail,
          } as ChatHistoryResponse;
          setChatHistory((history) => [body, ...history]);
          scrollToBottom();
        }
      });
    }
  };

  const joinRoom = useCallback(
    (connectionId: string) => {
      console.log("chala", connectionId);

      const data = {
        connectionId: connectionId,
      };

      socket?.emit("connectToChat", data);
      socket?.once("connectToChatOk", async (message: any) => {
        console.log(message, "===room join====");
        scrollToBottom();
      });
    },
    [socket]
  );

  useEffect(() => {
    recieveChat();
  }, []);

  useEffect(() => {
    if (selectedRoomId?.length) {
      joinRoom(selectedRoomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomId]);

  useEffect(() => {
    if (state && state.type === "chat") {
      setSelectedRoomId(state.roomId);
      setSelectedReceiverId(state.senderId);
      setSelectedReceiverImage(state.senderImage);
      setSelectedReceiverName(state.senderName);
    } else {
      setSelectedRoomId("");
      setSelectedReceiverId("");
      setSelectedReceiverImage("");
    }
  }, [chats]);

  useEffect(() => {
    getChatListApi();
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading || getChatHistoryData?.isLoading || loading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="pMain" style={{ alignItems: "normal" }}>
              <div className="pLeft" style={{ padding: 10 }}>
                <ul className="chat">
                  {chats?.length ? (
                    chats?.map((item) => (
                      <li
                        key={item?.lastMessage?._id}
                        onClickCapture={() => handleChatClick(item)}
                      >
                        <figure>
                          <img
                            src={item?.image || "/static/images/userDiv.png"}
                            alt=""
                            style={{ borderRadius: "100%" }}
                          />
                        </figure>
                        <h5>
                          {item?.lastMessage?.createdAt
                            ? moment(item?.lastMessage?.createdAt).format(
                                "MMM Do YY"
                              )
                            : ""}
                        </h5>
                        <div className="contnt">
                          <h3>{item?.fullName || ""}</h3>
                          <p>
                            {item?.lastMessage?.type === SOCKET_KEYS.text
                              ? item.lastMessage.text.length > 12
                                ? item.lastMessage.text.substring(0, 20) + "..."
                                : item.lastMessage.text
                              : translation.chat.sharedMedia}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="no_Chat">{translation.chat.no_chats}</div>
                  )}
                </ul>
              </div>
              <div className="pRytchat">
                {selectedReceiverName ? (
                  <ChatDetail
                    chatHistory={chatHistory}
                    setText={setText}
                    text={text}
                    setMedia={setMedia}
                    media={media}
                    selectedReceiverImage={selectedReceiverImage}
                    selectedReceiverName={selectedReceiverName}
                    sendMessage={sendMessage}
                    setMessageType={setMessageType}
                    messageType={messageType}
                    loading={loading}
                    setLoading={setLoading}
                  />
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <figure
                      style={{
                        height: "70px",
                        width: "60px",
                        paddingBottom: "10px",
                      }}
                    >
                      <img src="/static/images/chat_img.png" alt="" />
                    </figure>
                    {translation.chat.go_ahead}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chat;
