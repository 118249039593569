import React from "react";
import { useNavigate } from "react-router-dom";
import { BidRes, ListedPost } from "../types/General";
import { letters, number } from "../utils/validation";
import moment from "moment";
import useTranslation from "../hooks/Translation";

type props = {
  createdAt?: string;
  category?: string;
  posttitle?: string;
  price?: number | string;
  carPlatenumber?: string;
  _id?: string;
  state?: string;
  lastBid?: number;
  bidingEndTime?: string;
  role?: string;
  item?: BidRes;
};

const MyBidCard = ({
  category,
  _id,
  posttitle,
  price,
  carPlatenumber,
  state,
  createdAt,
  lastBid,
  bidingEndTime,
  role,
  item,
}: props) => {
  const navigate = useNavigate();
  const translation = useTranslation();

  return (
    <div
      className="bidDiv"
      key={_id}
      onClick={() =>
        state === "review"
          ? navigate(`/car-plate-detail/${_id}`)
          : navigate(`/bid-detail/${_id}`)
      }
    >
      <div className="bdLeft">
        <div className="bidLeft" style={{ width: "52%" }}>
          <div className="plate_box">
            <ul className="pLft">
              <li className="pro_sans">
                {" "}
                {carPlatenumber
                  ?.split("")
                  /* @ts-ignore */
                  ?.map((item: string) => number[`${item}`])
                  ?.reverse()
                  ?.join(" ")}
              </li>
              <li className="pro_sans">
                {posttitle
                  ?.split("")
                  /* @ts-ignore */
                  ?.map((item: string) => letters[`${item?.toLowerCase()}`])
                  ?.reverse()
                  ?.join(" ")}
              </li>
              <li className="pro_sans">
                {" "}
                {carPlatenumber ? carPlatenumber.split("").join(" ") : ""}
              </li>
              <li className="pro_sans">
                {" "}
                {posttitle ? posttitle.split("").join(" ") : ""}
              </li>
            </ul>
            <div
              className="pRyt"
              style={{
                background: category === "Pick-up Car" ? "#3c82c8" : "",
              }}
            >
              <figure>
                <img src="/static/images/lowhate_icon.png" alt="" />
              </figure>
              <p>السعودية</p>
              <div className="ksa">
                <h4>K</h4>
                <h4>S</h4>
                <h4>A</h4>
              </div>
              <div className="greenDot" />
            </div>
          </div>
        </div>
        <div className="bidRyt">
          <h2>
            {posttitle || ""} <span className=""> {carPlatenumber || ""}</span>
          </h2>
          {state == "review" ? (
            <p>
              {translation.Globals.price}:{" "}
              <span className=""> {price || "0"} </span> <span>SAR</span>
            </p>
          ) : (
            <h3>
              {translation.Globals.lastBid}:{" "}
              <span className=""> {lastBid || "0"} </span> <span>SAR</span>
            </h3>
          )}
          {state !== "review" ? (
            <>
              {item?.postDetails?.bidding?.isWinner ? (
                <span className="won_tag">{translation.Globals.won}</span>
              ) : (
                <span className="won_tag" style={{ background: "#204e33" }}>
                  {translation.Globals.loose}
                </span>
              )}
              {/* <p>
                Ending Time:{" "}
                <span>
                  {moment(bidingEndTime).format("MMM Do YYYY, h:mm:ss a")}
                </span>
              </p> */}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {state !== "review" ? (
        <div className="bdRyt">
          <p>{moment(createdAt).format("ll")}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyBidCard;
