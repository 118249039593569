import { Button as _Button } from "@mui/material";
import { Colors } from "../assets";

type ButtonProps = {
  value: string;
  gradient?: boolean;
  fontSize?: number;
  onClick?: () => void;
  disabled?: boolean;
};

const Button = ({
  value,
  onClick,
  gradient = false,
  fontSize = 18,
  disabled,
}: ButtonProps) => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Button
      disabled={disabled}
      type="submit"
      onClick={onClick}
      style={{
        background: gradient
          ? "linear-gradient(180deg, #FA6866 0%, #F7895A 100%)"
          : Colors.primary,
        fontFamily: "Product Sans, Bold",
        color: Colors.white,
        fontSize,
        width: "100%",
        textTransform: "capitalize",
        borderRadius: "5px",
        padding: 6,
      }}
      variant="contained"
    >
      {value}
    </_Button>
  );
};

export default Button;
