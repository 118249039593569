import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const otpStyle = {
  width: "24%",
  padding: "20px",
  margin: "0 auto",
  backgroundColor: "white",
  borderRadius: "5px",
  fontSize: "20px",
  fontWeight: 600,
};

export const generateResponsiveStyle = () => {
  //   const theme = useTheme();
  //   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust the breakpoint as needed

  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 430,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    borderRadius: 4,
    outline: "none",
  };
};
