import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./comment.scss";
import { Button, CarNumberPlate, PlateDetailCard } from "../../components";
import { useLocation, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../helpers";
import {
  useAddCommentOnPostMutation,
  useChangeFavStatusMutation,
  useLazyGetCommmentOnPostQuery,
} from "../../service/home";
import { Comment } from "../../types/General";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import useTranslation from "../../hooks/Translation";

const Comments = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const translation = useTranslation();

  const [getCommentsMutation] = useLazyGetCommmentOnPostQuery();
  const [addComment, { isLoading }] = useAddCommentOnPostMutation();
  const [changeFavStatus] = useChangeFavStatusMutation();

  const [allComments, setAllComments] = useState<Comment[]>([]);
  const [comment, setComment] = useState("");
  const [selectedMsg, setSelectedMsg] = useState<string>("");
  const [openWarn, setOpenWarn] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [subReply, setSubReply] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //handleFav
  const handleFav = async (e: any, postId: string) => {
    e.stopPropagation();

    const data = {
      postId: postId,
    };

    try {
      const response = await changeFavStatus(data).unwrap();
      if (response?.statusCode === 200) {
        if (id) {
          getComments(id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddComment = async (id: string | undefined, comment: string) => {
    const body = {
      postId: id,
      comment,
    };

    if (id) {
      try {
        const response = await addComment(body).unwrap();
        if (response?.statusCode === 200) {
          successToast(translation.toast_messages.commentAdded);
          setComment("");
          getComments(id);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const handleAddSubComment = async (
    id: string | undefined,
    comment: string,
    parentId: string
  ) => {
    const body = {
      postId: id,
      comment,
      parentId: parentId,
    };

    if (id) {
      try {
        const response = await addComment(body).unwrap();
        if (response?.statusCode === 200) {
          setSubReply("");
          getComments(id);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getComments = async (id: string) => {
    try {
      const response = await getCommentsMutation({
        id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllComments(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getComments(id);
    }
  }, []);

  return (
    <Layout>
      <div className="wrapCls ">
        <div className="comment_card">
          <div className="container">
            <h1 className="prdct_dtl_hdng">{translation.Globals.comment}</h1>
            <div className="section">
              <div className="left">
                <div className="dLeft">
                  <CarNumberPlate
                    category={state?.category}
                    posttitle={state?.posttitle}
                    carPlatenumber={state?.carPlatenumber}
                  />
                </div>
                <div style={{ paddingTop: 30 }} />
                <PlateDetailCard
                  path="comment"
                  posttitle={state?.posttitle}
                  price={state?.price}
                  carPlatenumber={state?.carPlatenumber}
                  _id={state?._id}
                  role={state?.role}
                  postCreatedBy={state?.userId}
                  createdAt={state?.createdAt}
                  typeOfPrice={state?.typeOfPrice}
                  setSelectedId={setSelectedId}
                  setOpenWarn={setOpenWarn}
                  listedPost={state}
                  handleFav={handleFav}
                />
              </div>
              <div className="right">
                <div className="comment_card">
                  {allComments?.length ? (
                    allComments?.map((item) => (
                      <div className="comment_section">
                        <p>{item?.userName || ""}</p>
                        <p className="message">{item?.comment || ""}</p>

                        <div className="subComments">
                          {item?.subComments?.length
                            ? item?.subComments?.map((item) => (
                                <h3 key={item?._id}>
                                  {item?.subCommentDetails?.fullName || ""} :{" "}
                                  <span> {item?.comment || ""} </span>
                                </h3>
                              ))
                            : null}
                        </div>
                        <p
                          className="reply"
                          onClick={() => {
                            setSelectedMsg(item?._id);
                            handleClickOpen();
                          }}
                        >
                          {translation.posts.reply}
                        </p>

                        <div className="horizontal_line" />
                      </div>
                    ))
                  ) : (
                    <div className="noData" style={{ margin: "60px 0px" }}>
                      <figure>
                        <img src="/static/images/noData.webp" alt="" />
                      </figure>
                      <h3> {translation.error.noData}</h3>
                    </div>
                  )}
                </div>
                <div className="comment_box">
                  <input
                    className="comnt_input"
                    placeholder={translation.Globals.addComment}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <figure onClick={() => handleAddComment(id, comment)}>
                    <img src="/static/images/send_icon.png" alt="send icon" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <Dialog
          // style={{ width: 400 }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              const email = formJson.email;
              console.log(email);
              handleClose();
            },
          }}
        >
          {/* <DialogTitle>Subscribe</DialogTitle> */}
          <DialogContent>
            <DialogContentText style={{ paddingRight: "250px" }}>
              {translation.posts.writeReply}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="email"
              label={translation.posts.reply}
              type="text"
              fullWidth
              variant="standard"
              value={subReply}
              onChange={(e) => setSubReply(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              value={translation.Globals.cancel}
              onClick={handleClose}
            ></Button>
            <Button
              value={translation.posts.reply}
              onClick={() => handleAddSubComment(id, subReply, selectedMsg)}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Layout>
  );
};

export default Comments;
