import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import React, { useEffect, useState } from "react";
import { InputField } from "../../components";
import {
  useDeleteAddressMutation,
  useLazyGetAddressByIdQuery,
  useLazyGetAllAddressQuery,
  usePostAddAddressMutation,
  useUpdateAddressMutation,
} from "../../service/Common";
import { errorToast, successToast } from "../../helpers";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  Autocomplete,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../helpers/constants/urls";
import useTranslation from "../../hooks/Translation";

const ManageAddresses = () => {
  const translation = useTranslation();

  const [value, setValue] = useState("Home");
  const [addAddress] = usePostAddAddressMutation();
  const [allAddressQuery] = useLazyGetAllAddressQuery();
  const [deleteAddress] = useDeleteAddressMutation();
  const [getAddressById] = useLazyGetAddressByIdQuery();
  const [updateAddress] = useUpdateAddressMutation();
  const style = generateResponsiveStyle();
  const [activeButton, setActiveButton] = useState("1");
  const [allAddress, setAllAddress] = useState<any>([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Get the address using reverse geocoding
          const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`;

          fetch(geocodingApiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.results && data.results.length > 0) {
                const address = data.results[0].formatted_address;
                const locality =
                  data.results[0].address_components[4].long_name;

                // Set the values in  formik form
                formik.setFieldValue("lat", latitude);
                formik.setFieldValue("long", longitude);
                formik.setFieldValue("address", address);
                formik.setFieldValue("house", locality);
              } else {
                console.error("No results found for reverse geocoding");
              }
            })
            .catch((error) => {
              console.error("Error fetching reverse geocoding data:", error);
            });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation or Google Map not available");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: "",
      house: "",
      landmark: "",
      lat: "",
      long: "",
      zip: "",
      country: "",
      state: "",
    },

    validationSchema: Yup.object({
      address: Yup.string().required(translation.validations.required_field),
      // .min(2, "Minimum 2 characters are required"),
      // house: Yup.string()
      //   .required("This field is required")
      //   .min(2, "Minimum 2 characters are required"),

      landmark: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const data = {
        address: formik.values.address,
        country: formik.values.country,
        state: formik.values.state,
        houseNumber: formik.values.house,
        landmark: formik.values.landmark,
        // zipcode: formik.values.zip,
        latitude: String(formik.values.lat) || "30.713061321086474",
        longitude: String(formik.values.long) || "76.70939868316054",
        type:
          activeButton === "1"
            ? "Home"
            : activeButton === "2"
            ? "Work"
            : "Other",
      };

      try {
        if (showAddAddress && isAdd) {
          const res = await addAddress(data).unwrap();
          if (res?.statusCode === 200) {
            successToast(translation.toast_messages.address_added);
            formik.resetForm();
            setShowAddAddress(false);
            getAllAddress();
          }
        } else {
          const res = await updateAddress({
            body: data,
            id: selectedId,
          }).unwrap();
          if (res?.statusCode === 200) {
            setSelectedId(selectedId);
            setShowAddAddress(false);
            successToast(translation.toast_messages.address_updated);
            formik.resetForm();
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }

      formik.setSubmitting(false);
    },
  });

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();
      if (place && place.address_components) {
        console.log("place: ", place);
        let address = place.address_components;

        let state = "",
          city = "",
          zip = "",
          country = "";

        address.forEach(function (component: any) {
          console.log("component: ", component);
          let types = component.types;
          if (types.indexOf("locality") > -1) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (place.address_components?.length) {
            country =
              place.address_components[place.address_components?.length - 1]
                ?.long_name;
          }
        });

        var lat = place.geometry.location.lat();
        // get lng
        var lng = place.geometry.location.lng();

        formik.setFieldValue("address", `${place?.formatted_address}`);
        formik.setFieldValue("house", `${place?.vicinity}`);
        formik.setFieldValue("lat", lat || "");
        formik.setFieldValue("long", lng || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zip", zip || "");
        formik.setFieldValue("country", country || "");
      }
    }
  };

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const handleAddAddress = () => {
    setIsAdd(true);
    setShowAddAddress(true);
    setActiveButton("1");
    formik.setFieldValue("address", "");
    formik.setFieldValue("house", "");
    formik.setFieldValue("landmark", "");
    formik.setFieldValue("country", "");
    setSelectedId("");
  };

  const handleDeleteAddress = async (id: string) => {
    try {
      const res = await deleteAddress({ id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(translation.toast_messages.address_deleted);
        getAllAddress();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllAddress = async () => {
    try {
      const response = await allAddressQuery({}).unwrap();
      if (response?.statusCode === 201 || 200) {
        if (response?.data) {
          setAllAddress(response?.data || []);
          // dispatch(
          //   addressData({
          //     address: response?.data?.address,
          //   })
          // );
          // setSelectedId(response?.data?.address[0]?._id);
        }
      } else {
        setAllAddress([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleEditAddress = async (id: string) => {
    try {
      const res = await getAddressById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setIsAdd(false);
        formik.setFieldValue("address", res?.data[0]?.address);
        // formik.setFieldValue("house", res?.data[0]?.houseNumber);
        formik.setFieldValue("landmark", res?.data[0]?.landmark);
        setSelectedId(res?.data[0]?._id);
        // setActiveButton(
        //   res?.data?.saveAs === "Home"
        //     ? "1"
        //     : res?.data?.saveAs === "Work"
        //     ? "2"
        //     : "3"
        // );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    handleGetLocation();
  }, [showAddAddress]);

  return (
    <>
      {showAddAddress ? (
        <div className="main new_addrss">
          <h2>{translation.profile.add_address} </h2>
          <figure>
            <img src="/static/images/map.png" alt="" />
          </figure>
          {/* <div className="adrss_mn">
            <FmdGoodIcon />
            <h4>{formik.values.address || ""}</h4>
          </div> */}
          {/* <p>8591 Ramblewood St. Lower Manhattan Grovetown, GA 30813</p> */}
          <form onSubmit={formik.handleSubmit}>
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={() => onPlaceChanged()}
            >
              <TextField
                className="text_field"
                hiddenLabel
                type={"text"}
                fullWidth
                placeholder={translation.profile.house}
                name="address"
                variant="outlined"
                id="address"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                helperText={formik.touched.address && formik.errors.address}
                onKeyPress={onKeypress}
              />
            </Autocomplete>
            {/* <InputField
              name="address"
              id="address"
              placeholder="House / Flat / Block No."
              value={formik.values.address}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.address && formik.errors.address}
            /> */}
            <InputField
              name="landmark"
              id="landmark"
              placeholder={translation.profile.landmark}
              value={formik.values.landmark}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.landmark && formik.errors.landmark}
            />
            <h4>Save As</h4>
            <div className="save_as">
              <div
                className={
                  activeButton === "1"
                    ? "active_btn saveas_btns"
                    : "saveas_btns"
                }
                onClick={() => setActiveButton("1")}
              >
                {translation.profile.home}
              </div>
              <div
                onClick={() => setActiveButton("2")}
                className={
                  activeButton === "2"
                    ? "active_btn saveas_btns"
                    : "saveas_btns"
                }
              >
                {translation.profile.work}
              </div>
              <div
                onClick={() => setActiveButton("3")}
                className={
                  activeButton === "3"
                    ? "active_btn saveas_btns"
                    : "saveas_btns"
                }
              >
                {translation.profile.other}
              </div>
            </div>
            <button> {translation.Globals.submit}</button>
          </form>
        </div>
      ) : (
        <div className="main" style={{ padding: 0 }}>
          <div className="flx_dv_prfl">
            <Button
              type="button"
              className="hdr_btn"
              onClick={handleAddAddress}
            >
              <figure>
                <img src="/static/images/add_address.png" alt="" />
              </figure>
              {translation.profile.add_address}
            </Button>
          </div>
          {/* <div className="saved_addrrss">
            <p style={{ padding: "20px" }}>Saved Addresses</p>
          </div> */}
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {allAddress?.map((item: any) => (
                <div className="adrss_outr">
                  <div className="adrs_mn">
                    <div className="adrs_lft">
                      {/* <FmdGoodIcon /> */}
                      <figure>
                        <img src="/static/images/locationIcon.png" alt="" />
                      </figure>
                      <div className="adrs_dtls">
                        <h4>
                          {item?.type || "Other"} {translation.Globals.address}
                        </h4>
                        <p>{`${item?.address} ${item?.state}`}</p>
                        <p>{item?.landmark}</p>
                      </div>
                    </div>
                    {/* <FormControlLabel value="Home" control={<Radio />} label="" /> */}
                    <div className="edit_adrss">
                      <button
                        onClick={() => {
                          setShowAddAddress(true);
                          handleEditAddress(item?._id);
                        }}
                      >
                        {translation.profile.edit}
                      </button>
                      <figure
                        onClick={() => {
                          setOpenModal(true);
                          setSelectedId(item?._id);
                        }}
                      >
                        <img src="/static/images/delete.png" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )}

      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.profile.delete_address}
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => {
                  setOpenModal(false);
                  handleDeleteAddress(selectedId);
                }}
              >
                {translation.Globals.yes}
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenModal(false)}
              >
                {translation.Globals.no}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ManageAddresses;
