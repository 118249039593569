import { END_POINTS } from "../helpers";
import {
  AllServiceType,
  Category,
  Comment,
  Dashboard,
  Earning,
  GraphType,
  ListedPost,
  MyReview,
  PostService,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type ReviewRes = {
  avgRating: number;
  reviewCount: number;
  review: MyReview[];
};

type HomeSearchParams = {
  category: string;
  posttitle: string;
  carPlatenumber: string;
  userId?: string;
};

type filterParmas = {
  type: string;
  categoryId?: string;
  maxPrice?: number;
  minPrice?: number;
  limit?: number;
  page?: number;
  userId?: string;
};

export const homeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: Dashboard },
      { categoryId: string; userId: string }
    >({
      query: ({ categoryId, userId }) => ({
        url: `${END_POINTS.dashboard}?categoryId=${categoryId}${
          userId ? `&userId=${userId}` : ""
        }`,
        method: "GET",
      }),
    }),
    getPostById: builder.query<
      CommonResponseType & { data: ListedPost },
      { id: string; userId?: string }
    >({
      query: ({ id, userId }) => ({
        url: `${END_POINTS.getPostById}/${id}${
          userId ? `?userId=${userId}` : ""
        }`,
        method: "GET",
      }),
    }),
    changeFavStatus: builder.mutation<
      CommonResponseType & { data: ListedPost },
      { postId: string | undefined }
    >({
      query: (body) => ({
        url: `${END_POINTS.addorRemovefromWishlist}`,
        method: "POST",
        body,
      }),
    }),
    addRemoveLike: builder.mutation<
      CommonResponseType & { data: ListedPost },
      { postId: string | undefined }
    >({
      query: (body) => ({
        url: `${END_POINTS.addorRemovelike}`,
        method: "POST",
        body,
      }),
    }),
    getAllServices: builder.query<
      CommonResponseType & { data: AllServiceType[] },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.allServices}`,
        method: "GET",
      }),
    }),
    getMyEarning: builder.query<
      CommonResponseType & { data: { earning: Earning[] } },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.myEarnings}`,
        method: "GET",
      }),
    }),

    getMyEarningGraph: builder.query<
      CommonResponseType & {
        data: {
          earningCount: GraphType[];
        };
      },
      { totalEarning: string }
    >({
      query: ({ totalEarning }) => ({
        url: `${END_POINTS.myEarningGraph}?type=${totalEarning}`,
        method: "GET",
      }),
    }),

    getMyReview: builder.query<CommonResponseType & { data: ReviewRes }, {}>({
      query: () => ({
        url: `${END_POINTS.review}`,
        method: "GET",
      }),
    }),

    //comment
    getCommmentOnPost: builder.query<
      CommonResponseType & { data: Comment[] },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.comment}/${id}`,
        method: "GET",
      }),
    }),

    addCommentOnPost: builder.mutation<
      CommonResponseType & { data: Comment },
      { postId: string | undefined; comment: string; parentId?: string }
    >({
      query: (body) => ({
        url: `${END_POINTS.comment}`,
        method: "POST",
        body,
      }),
    }),
    //comment
    globalSearch: builder.query<
      CommonResponseType & { data: ListedPost[] },
      HomeSearchParams
    >({
      query: ({ category, userId, posttitle, carPlatenumber }) => ({
        url: `${
          END_POINTS.homeSearchApi
        }?category=${category}&posttitle=${posttitle}&carPlatenumber=${carPlatenumber}${
          userId ? `&userId=${userId}` : ""
        }`,
        method: "GET",
      }),
    }),
    notifyUser: builder.mutation<
      CommonResponseType & { data: ListedPost },
      { posttitle: string; carPlatenumber: string }
    >({
      query: (body) => ({
        url: `${END_POINTS.notifyUser}`,
        method: "POST",
        body,
      }),
    }),
    filterPost: builder.mutation<
      CommonResponseType & { data: { post: ListedPost[]; count: number } },
      filterParmas
    >({
      query: (body) => ({
        url: `${END_POINTS.viewAllPost}`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetDashboardQuery,
  useLazyGetPostByIdQuery,
  useChangeFavStatusMutation,
  useLazyGetAllServicesQuery,
  useLazyGetMyEarningQuery,
  useAddRemoveLikeMutation,
  useLazyGetMyReviewQuery,
  useLazyGlobalSearchQuery,
  useNotifyUserMutation,
  useFilterPostMutation,
  useLazyGetCommmentOnPostQuery,
  useAddCommentOnPostMutation,
  useLazyGetMyEarningGraphQuery,
} = homeApi;
