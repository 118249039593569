import Filters from "./filters";
import HomePage from "./home";
import Review from "./review";
import Search from "./search";
import SeeAllPage from "./seeAll";
import AddPostSell from "./addPostSell";
import AddAuction from "./addAuction";
import AddOfferPost from "./addOffer";
import PlateDetail from "./plateDetail";
import MyBids from "./myBids";
import Comments from "./comments";
import BidDetail from "./bidDetail";
import Services from "./service";
import AboutUs from "./aboutUs";
import PrivacyPolicy from "./privacyPolicy";
import ContactUs from "./contactUs";
import ViewOffer from "./viewOffers";
import SellerDetail from "./sellerDetail";
import AgentForService from "./agentForService";
import SeeAllBidders from "./seeAllBidders";
import Chat from "./chat";
import Notifications from "./notifications";
import Profile from "./profile";
import PaymentMethod from "./managePayment";
import BookingDetail from "./bookingDetails";
import Terms from "./terms";

export default {
  SeeAllBidders,
  AgentForService,
  SellerDetail,
  ViewOffer,
  AddPostSell,
  AddAuction,
  AboutUs,
  PrivacyPolicy,
  ContactUs,
  Services,
  BidDetail,
  MyBids,
  PaymentMethod,
  PlateDetail,
  HomePage,
  Profile,
  Chat,
  Filters,
  Notifications,
  Search,
  Comments,
  Review,
  BookingDetail,
  AddOfferPost,
  SeeAllPage,
  Terms,
};
