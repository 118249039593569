// import React, { useEffect, useState } from "react";
// import Layout from "../../layout";
// import { MyBidCard } from "../../components";
// import "./Bids.scss";
// import { useLazyGetAllMyBIdQuery } from "../../service/bidding";
// import { Loader, errorToast } from "../../helpers";
// import { BidRes } from "../../types/General";
// import useTranslation from "../../hooks/Translation";




// type props = {
//   profile?: boolean;
// }

// const MyBids = ({ profile }: props) => {
//   const translation = useTranslation();

//   const [getAllBidMutation, { isLoading }] = useLazyGetAllMyBIdQuery();
//   const [myBids, setMyBids] = useState<BidRes[]>([]);
//   const getAllBid = async () => {
//     try {
//       const response = await getAllBidMutation({}).unwrap();
//       if (response?.statusCode === 200) {
//         setMyBids(response?.data?.post || []);
//       }
//     } catch (error: any) {
//       errorToast(error?.data?.message || "");
//     }
//   };

//   useEffect(() => {
//     getAllBid();
//   }, []);

//   return (
//     <Layout>
//       <Loader isLoad={isLoading} />
//       <div className="wrapCls">
//         <div className="comment_card">
//           <div className="container">
//             <h1 className="prdct_dtl_hdng">{translation.bids.myBids}</h1>
//             <div className="bids_div">
//               {myBids?.length ? (
//                 myBids?.map((item) => (
//                   <MyBidCard
//                     lastBid={item?.postDetails?.mybidding?.price}
//                     bidingEndTime={item?.postDetails?.bidingEndTime}
//                     createdAt={item?.createdAt}
//                     posttitle={item?.postDetails?.posttitle}
//                     price={item?.postDetails?.posttitle}
//                     carPlatenumber={item?.postDetails?.carPlatenumber}
//                     _id={item?.postId}
//                     item={item}
//                   />
//                 ))
//               ) : (
//                 <div className="noData">
//                   <figure>
//                     <img src="/static/images/noData.webp" alt="" />
//                   </figure>
//                   <h3> {translation.error.noData}</h3>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </Layout>
//   );
// };


// export default MyBids;

import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { MyBidCard } from "../../components";
import "./Bids.scss";
import { useLazyGetAllMyBIdQuery } from "../../service/bidding";
import { Loader, errorToast } from "../../helpers";
import { BidRes } from "../../types/General";
import useTranslation from "../../hooks/Translation";

type Props = {
  profile?: boolean;
};

const MyBids = ({ profile }: Props) => {
  const translation = useTranslation();

  const [getAllBidMutation, { isLoading }] = useLazyGetAllMyBIdQuery();
  const [myBids, setMyBids] = useState<BidRes[]>([]);

  const getAllBid = async () => {
    try {
      const response = await getAllBidMutation({}).unwrap();
      if (response?.statusCode === 200) {
        setMyBids(response?.data?.post || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllBid();
  }, []);

  const content = (
    <>
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <h1 className="prdct_dtl_hdng">{translation.bids.myBids}</h1>
            <div className="bids_div" >
              {myBids?.length ? (
                myBids?.map((item) => (
                  <MyBidCard
                    key={item?.postId}
                    lastBid={item?.postDetails?.mybidding?.price}
                    bidingEndTime={item?.postDetails?.bidingEndTime}
                    createdAt={item?.createdAt}
                    posttitle={item?.postDetails?.posttitle}
                    price={item?.postDetails?.posttitle}
                    carPlatenumber={item?.postDetails?.carPlatenumber}
                    _id={item?.postId}
                    item={item}
                  />
                ))
              ) : (
                <div className="noData">
                  <figure>
                    <img src="/static/images/noData.webp" alt="" />
                  </figure>
                  <h3>{translation.error.noData}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return profile ? content : <Layout>{content}</Layout>;
};

export default MyBids;
