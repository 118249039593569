export const isString = (val: any) => {
  if (val.includes(".")) {
    return true;
  }
  if (val.length === 1 && val === " ") {
    return false;
  }
  if (
    val[val.length - 1] === " " &&
    val[val.length - 1] !== val[val.length - 2]
  ) {
    return true;
  }
  if (
    val[val.length - 1]?.trim()?.toLowerCase() !==
      val[val.length - 1]?.trim()?.toUpperCase() ||
    val === ""
  ) {
    return true;
  }
  return false;
};
export const isFloat = (val: any) => {
  if (val[val.length - 1] === " " || val === "." || val === "0") {
    return false;
  }
  if (val.includes(".")) {
    val = val.replace(".", "");
    // eslint-disable-next-line no-restricted-globals
    if ((!val.includes(".") && !isNaN(val?.trim())) || val === "") {
      return true;
    }
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }
  return false;
};
export const isNumber = (val: any) => {
  if (val[val.length - 1] === " ") {
    return false;
  }
  if (val.includes(".")) {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }
  return false;
};

export const isValidInput = (value: string) => {
  if (value === "") {
    return true;
  }
  if (value.trim() === "") {
    return false; // Reject if the value is empty or only consists of whitespace
  }

  if (value.includes("  ") || value.includes("..")) {
    return false; // Reject if there are consecutive spaces or decimals
  }

  return true; // Accept the input if it meets all the conditions
};

export const number = {
  "0": "٠",
  "1": "١",
  "2": "٢",
  "3": "٣",
  "4": "٤",
  "5": "٥",
  "6": "٦",
  "7": "٧",
  "8": "٨",
  "9": "٩",
};

export const letters = {
  a: "ا",
  b: "ب",
  c: "ث",
  d: "د",
  e: "ع",
  f: "و",
  g: "ق",
  h: "ح",
  i: "ي",
  j: "ح",
  k: "ك",
  l: "ل",
  m: "م",
  n: "ن",
  o: "ع",
  p: "ب",
  q: "ق",
  r: "ر",
  s: "ص",
  t: "ط",
  u: "و",
  v: "ى",
  w: "و",
  x: "ص",
  y: "ي",
  z: "م",
};

export const saudiCitiesList = [
  "Abha",
  "Ad-Dilam",
  "Al Artaweeiyah",
  "Al Bukayriyah",
  "Badr",
  "Baljurashi",
  "Bisha",
  "Bareq",
  "Buraydah",
  "Al Bahah",
  "Buqaa",
  "Dammam",
  "Dhahran",
  "Dhurma",
  "Dahaban",
  "Diriyah",
  "Duba",
  "Dumat Al-Jandal",
  "Dawadmi",
  "Farasan",
  "Gatgat",
  "Gerrha",
  "Ghawiyah (Jizan)",
  "Al-Gwei'iyyah",
  "Hautat Sudair",
  "Habaala",
  "Hajrah",
  "Haql",
  "Al-Hareeq",
  "Harmah",
  "Ha'il",
  "Hotat Bani Tamim",
  "Hofuf",
  "Huraymila",
  "Hafr Al-Batin",
  "Jabal Umm al Ru'us",
  "Jalajil",
  "Jeddah",
  "Jizan",
  "Jazan Economic City",
  "Jubail",
  "Al Jafr",
  "Khafji",
  "Khaybar",
  "King Abdullah Economic City",
  "Khamis Mushait",
  "Al-Saih",
  "Knowledge Economic City",
  "Medina",
  "Khobar",
  "Al-Khutt",
  "Lihyan",
  "Al Lith",
  "Al Majma",
  "Mastoorah",
  "Al Mikhwah",
  "Al-Mubarraz",
  "Al Mawain",
  "Medina",
  "Mecca",
  "Muzahmiyya",
  "Najran",
  "Al-Namas",
  "Umluj",
  "Al-Omran",
  "Al-Oyoon",
  "Qadeimah",
  "Qatif",
  "Qaisumah",
  "Al Qadeeh",
  "Al Qunfudhah",
  "Qurayyat",
  "Rabigh",
  "Rafha",
  "Ar Rass",
  "Ras Tanura",
  "Rumah",
  "Ranyah",
  "Riyadh",
  "Riyadh Al-Khabra",
  "Rumailah",
  "Sabt Al Alaya",
  "Sarat Abidah",
  "Saihat",
  "Safwa city",
  "Sakakah",
  "Sharurah",
  "Shaqraa",
  "Shaybah",
  "As Sulayyil",
  "Taif",
  "Tabuk",
  "Tanomah",
  "Tarout",
  "Tayma",
  "Thadiq",
  "Thuwal",
  "Ath Thuqbah|Thuqbah",
  "Turaif",
  "Tabarjal",
  "Udhailiyah",
  "Al-'Ula",
  "Um Al-Sahek",
  "Unaizah",
  "Uqair",
  "Uyayna",
  "Uyun AlJiwa",
  "Wadi Al-Dawasir",
  "Al Wajh",
  "Yanbu",
  "Az Zaimah",
  "Zulfi",
];
