import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD-0JM53TkxDo5WxyummSSeni5zLyed-dA",
  authDomain: "lowhate-f39f2.firebaseapp.com",
  projectId: "lowhate-f39f2",
  storageBucket: "lowhate-f39f2.appspot.com",
  messagingSenderId: "630361815067",
  appId: "1:630361815067:web:5b28d33b00a78df67a20be",
  measurementId: "G-9JDRVZPFJF",
};

const app = initializeApp(firebaseConfig);
export default app;
