import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  FormControl,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  useLazyGetMyEarningGraphQuery,
  useLazyGetMyEarningQuery,
} from "../../service/home";
import { errorToast } from "../../helpers";
import { Earning, GraphType } from "../../types/General";
import useTranslation from "../../hooks/Translation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const MyEarnings = () => {
  const translation = useTranslation();
  const [myEarning, { isLoading }] = useLazyGetMyEarningQuery();
  const [earningGraph] = useLazyGetMyEarningGraphQuery();

  const [earningNames, setEarningNames] = useState<string[]>([]);
  const [earningValues, setEarningValues] = useState<number[]>([]);
  const [earning, setEarning] = useState<Earning[]>([]);
  const [earningFilter, setEarningFilter] = useState<string>("monthly");

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const PropertyData = {
    labels: earningNames,
    datasets: [
      {
        label: "",
        data: earningValues,
        borderRadius: 8,
        hoverBackgroundColor: "#204E33",
      },
    ],
  };

  const getMyEarningGraph = async () => {
    try {
      const response = await earningGraph({
        totalEarning: earningFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setEarningNames(
          response?.data?.earningCount?.map((item: GraphType) =>
            item?._id?.charAt(0).toLocaleUpperCase()
          )
        );
        setEarningValues(
          response?.data?.earningCount?.map((item: GraphType) => item?.count)
        );
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const getMyEarning = async () => {
    try {
      const response = await myEarning({}).unwrap();
      if (response?.statusCode === 200) {
        setEarning(response?.data?.earning || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getMyEarning();
  }, []);

  useEffect(() => {
    getMyEarningGraph();
  }, [earningFilter]);

  return (
    <div className="main">
      <div className="payment_head">
        <h2>{translation.profile.paymentHistory}</h2>
      </div>

      <div className="payment_div">
        <div
          className="e_left"
          style={{ display: earning?.length ? "block" : "flex" }}
        >
          {earning?.length ? (
            earning?.map((items) => (
              <div className="history" key={items?._id}>
                <img src={items?.image} className="user_img" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ paddingLeft: "18px" }}>
                    <h4>{items?.name}</h4>
                    <p className="ordr_id">#{items?.orderId}</p>
                  </div>
                  <div style={{ paddingLeft: "18px" }}>
                    <p
                      style={{
                        color:
                          items?.status == "Pending" ? "#0077DD" : "#204E33",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {items?.status}
                    </p>
                    <p className="ordr_price">${items?.price}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="noData">
              <figure>
                <img src="/static/images/noData.webp" alt="" />
              </figure>
              <h3>{translation.error.noData}</h3>
            </div>
          )}
        </div>
        <div className="e_right">
          <div className="selectWeek">
            <h5></h5>
            <FormControl>
              <Select
                className="select_div"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={earningFilter}
                inputProps={{
                  "aria-label": "Without label",
                }}
                displayEmpty
                onChange={handleEarningChange}
              >
                <MenuItem value="" disabled>
                  {translation.Globals.select}
                </MenuItem>
                <MenuItem value="weekly">
                  {" "}
                  {translation.profile.Weekly}
                </MenuItem>
                <MenuItem value="monthly">
                  {" "}
                  {translation.profile.Monthly}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="graph">
            <Bar options={options} data={PropertyData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyEarnings;
