import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useLazyGetProfileQuery,
  usePostLoginMutation,
} from "../../service/Auth";
import "../auth/Auth.scss";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";
import { setCredentials, userLoginRes } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";
import { isNumber } from "../../utils/validation";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
  setSignUp: Dispatch<SetStateAction<boolean>>;
};

type CountryType = {
  code: string;
  icon: string;
  id: string;
  countryCode: string;
};


const Login = ({ closeModal, setPhone, setSignUp }: props) => {
  const translation = useTranslation();
  const fcmToken = getFromStorage(STORAGE_KEYS.fcmToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [getProfile, GetProfileData] = useLazyGetProfileQuery();
  const [phoneView, setPhoneView] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryType>({
    code: "(SA) +966",
    icon: "/static/images/su_flag.webp",
    id: "2",
    countryCode: "+966",
  });

  const countryCodes = [
    {
      code: "(IND) +91",
      icon: "/static/images/in_flag.png",
      id: "1",
      countryCode: "+91",
    },
    {
      code: "(SA) +966",
      icon: "/static/images/su_flag.webp",
      id: "2",
      countryCode: "+966",
    },
  ];


  const handleForgot = () => {
    closeModal();
    dispatch(
      toggleAuthModal({ isAuthModalVisible: true, ModalType: "forgot" })
    );
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      remember: false,
      phoneNumber: "",
      countryCode: "",
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      userName: Yup.string()
        .when('phoneView', {
          is: (value: boolean) => value === false,
          then: schema => schema
            .required(translation.validations.required_field)
            .min(2, translation.validations.min_two)
            .max(80, translation.validations.max_Eighty),
          otherwise: schema => schema.notRequired(),
        }),

      password: Yup.string()
        .required(translation.validations.required_field)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.password_validation
        ),
      phoneNumber: Yup.string()
        .when('phoneView', {
          is: (value: boolean) => value === true,
          then: schema => schema
            .required(translation.validations.required_field)
            .min(9, translation.validations.min_nine)
            .max(15, translation.validations.max_fifteen),
          otherwise: schema => schema.notRequired(),
        }),
    }),
    onSubmit: async (values: any) => {
      formik.setSubmitting(true);
      let body;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(formik.values.userName)) {
        body = {
          email: formik.values.userName,
          password: formik.values.password,
          deviceType: "WEB",
          deviceToken: fcmToken || null,
        }
      } else if (phoneView === true) {
        body = {
          phone: formik.values.phoneNumber,
          password: formik.values.password,
          deviceType: "WEB",
          deviceToken: fcmToken || null,
          countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
        }
      } else {
        body = {
          userName: formik.values.userName,
          password: formik.values.password,
          deviceType: "WEB",
          deviceToken: fcmToken || null,
        }
      }
      console.log(body, "body of login");


      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token)
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(formik.values.userName)
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || "",
            })
          );
          dispatch(
            userLoginRes({
              userLogin: true,
            })
          );
          getUserDetails();
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          closeModal();
          navigate("/");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  const handleChangeVendorSelect = (event: any, newValue: any) => {
    setSelectedCountry(newValue);
    formik.setFieldValue("userName", "");
  };

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("userName", rememberData?.userName);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="AuthWrap">
        <div className="cross">
          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2>{translation.Auth.Login.logintoLowhate}</h2>
        <p />

        <form onSubmit={formik.handleSubmit}>
          {phoneView ? (
            <div className="form_control">
              <div className="select_country">
                <p style={{ fontSize: "12px" }} className="select_p">
                  {translation.Auth.Sign_up.countryCode}
                </p>

                <Autocomplete
                  className="prfl_autocmplt"
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleChangeVendorSelect}
                  options={countryCodes}
                  getOptionLabel={(option) => `${option?.code}`}
                  sx={{ width: "auto" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={translation.Auth.Sign_up.selectCountry}
                    />
                  )}
                  defaultValue={countryCodes.find((option) =>
                    option.code.includes("(SA) +966")
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div className="contryCode-list">
                        <img src={option?.icon} alt="img" />
                        <span>{option?.code || ""}</span>
                      </div>
                    </li>
                  )}
                />

                <div className="horizontal_line" />

                <TextField
                  inputProps={{
                    maxLength: selectedCountry?.countryCode === "+91" ? 10 : 15,
                  }}
                  fullWidth
                  className="phoneField"
                  hiddenLabel
                  variant="outlined"
                  placeholder={translation.Globals.phone_number}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isNumber(val.target.value)) {
                      formik.setFieldValue("phoneNumber", val.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </div>
            </div>
          ) : (
            <InputField
              placeholder={translation.Globals.userName + " / " + translation.Globals.email}
              name="userName"
              id="userName"
              value={formik.values.userName}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.userName && formik.errors.userName}
            />
          )}

          <InputField
            placeholder={translation.Auth.Login.password}
            password
            name="password"
            id="password"
            value={formik.values.password}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.password && formik.errors.password}
          />{" "}

          <div className="forgotBtn" style={{ textAlign: "center" }}>
            <h4
              onClick={() => setPhoneView(!phoneView)}
            >
              {phoneView ? translation.Auth.Login.sign_up_with_email : translation.Auth.Login.sign_up_with_phone}
            </h4>
          </div>
          <div className="remmerbar">
            <FormControlLabel
              control={<Checkbox />}
              checked={formik.values.remember}
              name="remember"
              onChange={formik.handleChange}
              label={translation.Auth.Login.remember}
            />

            <div className="anchor_link">
              <h3 onClick={handleForgot}>
                {translation.Auth.Login.forgot_pas}
              </h3>
            </div>
          </div>
          <Button value="Login" />
        </form>


        <div className="mrgn_Btm" style={{ marginBottom: 80 }} />
        <div className="signUp">
          <h4>
            {translation.Auth.Login.not_member}{"? "}
            <span
              onClick={() => {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "signUp",
                  })
                );
                setSignUp(true);
              }}
            >
              {translation.Auth.Login.signup}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Login;
