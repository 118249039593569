import React from "react";
import useTranslation from "../../hooks/Translation";

const DownloadApp = () => {
  const translation = useTranslation();
  return (
    <section className="dwnld_bnr">
      <div className="mn_bnr_dwnld container">
        <div className="dwnld_bnr_lft">
          <h2>{translation?.home.download}</h2>
          <p>{translation?.home.buySell}</p>
          <div className="plystr_btns">
            <figure>
              <img src="/static/images/play_store.png" alt="" />
            </figure>
            <figure>
              <img src="/static/images/app_store.png" alt="" />
            </figure>
          </div>
        </div>
        <div className="dwnld_bnr_ryt">
          <figure>
            <img src="/static/images/bnr_phone.png" alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
