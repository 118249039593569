import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { AddOfferModal, Button } from "../../components";
import { useLocation, useParams } from "react-router-dom";
import { ListedPost, ReceiveOffer } from "../../types/General";
import moment from "moment";
import {
  useAddOfferToPostMutation,
  useLazyGetOffersOnPostQuery,
} from "../../service/posts";
import { Loader, errorToast, successToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import useTranslation from "../../hooks/Translation";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface OfferPost {
  createdAt: string;
  offerPrice: number;
  userName: string;
  userfullName: string;
  _id: string;
}
const ViewOffer = () => {
  const user = useAuth();
  const { id } = useParams();
  const translation = useTranslation();

  const [getPostOffer] = useLazyGetOffersOnPostQuery();
  const [makePostOfferMutation, { isLoading }] = useAddOfferToPostMutation();

  const [allOffers, setAllOffers] = useState<ReceiveOffer[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [openOffer, setOpenOffer] = React.useState(false);
  const handleOpen = () => setOpenOffer(true);

  const getPostOffers = async (id: string) => {
    try {
      const response = await getPostOffer({ id }).unwrap();
      if (response?.statusCode === 200) {
        setAllOffers(response?.data?.[0]?.reciveOffer || []);
        setUserId(response?.data?.[0]?.userId || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const makePostOffer = async () => {
    if (!offerPrice) {
      errorToast(translation.bids.addOffer);
      return;
    }
    if (id) {
      const body = {
        offerPrice: Number(offerPrice),
        postId: id,
      };
      try {
        const response = await makePostOfferMutation(body).unwrap();
        if (response?.statusCode === 200) {
          setOpenOffer(false);
          setOfferPrice("");
          successToast(`${translation.bids.madeOffer} ${offerPrice} SAR`);
          if (id) {
            getPostOffers(id);
          }
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };
  useEffect(() => {
    if (id) {
      getPostOffers(id);
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="addoffer">
              <h1 className="prdct_dtl_hdng">{translation.home.offerPost}</h1>
              {user?._id === userId ? null : (
                <div className="btnwidth">
                  <Button
                    value={translation.bids.addOffer1}
                    onClick={handleOpen}
                  />
                </div>
              )}
            </div>
            <div className="offerPostDiv">
              <ul>
                {allOffers?.length
                  ? allOffers?.map((item: OfferPost) => (
                      <li key={item?._id}>
                        <div className="anony">
                          <h3>{translation.bids.anonymousoffer}</h3>
                          <h4>{moment(item?.createdAt).format("LLL")}</h4>
                        </div>
                        <div className="PriceOffer">
                          <h2>{item?.offerPrice || "0"} SAR</h2>
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <AddOfferModal
        setOpen={setOpenOffer}
        open={openOffer}
        handleSubmit={() => makePostOffer()}
        setOfferPrice={setOfferPrice}
        offerPrice={offerPrice}
      />
    </Layout>
  );
};

export default ViewOffer;
