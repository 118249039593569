import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { BidderCard, Button, MyBidCard } from "../../components";
import {
  useLazyGetAllMyBIdQuery,
  useLazyGetBiddingDetailQuery,
  useLazySeeAllBiddersQuery,
} from "../../service/bidding";
import { Loader, errorToast } from "../../helpers";
import { BidRes, ReceiveOffer, SeeBidders } from "../../types/General";
import moment from "moment";
import {
  useAddOfferToPostMutation,
  useLazyGetOffersOnPostQuery,
} from "../../service/posts";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

const SeeAllBidders = () => {
  const user = useAuth();
  const { id } = useParams();
  const translation = useTranslation();

  const [biddingdetail, { isLoading }] = useLazySeeAllBiddersQuery();
  const [makePostOfferMutation] = useAddOfferToPostMutation();

  const [seeBids, setSeeBids] = useState<SeeBidders[]>([]);

  const [userId, setUserId] = useState<string>("");
  const [offerPrice, setOfferPrice] = useState<string>("");
  const [openOffer, setOpenOffer] = React.useState(false);
  const handleOpen = () => setOpenOffer(true);

  const getAllBidding = async (id: string) => {
    try {
      const response = await biddingdetail({ id }).unwrap();
      if (response?.statusCode === 200) {
        setSeeBids(response?.data?.bidders || []);
        // setUserId(response?.data?.[0]?.userId || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) {
      getAllBidding(id);
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="addoffer">
              <h1 className="prdct_dtl_hdng">{translation.bids.bidders}</h1>
            </div>
            <div className="bidder_box">
              {seeBids?.length ? (
                seeBids?.map((item) => <BidderCard item={item} />)
              ) : (
                <div className="noData">
                  <figure>
                    <img src="/static/images/noData.webp" alt="" />
                  </figure>
                  <h3> {translation.error.noData}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SeeAllBidders;
