import { END_POINTS } from "../helpers";
import {
  AllServiceType,
  BidRes,
  Category,
  ChatHistoryResponse,
  ChatResponse,
  Comment,
  Dashboard,
  ListedPost,
  MyReview,
  PostService,
  SeeBidders,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type ReviewRes = {
  avgRating: number;
  reviewCount: number;
  review: MyReview[];
};

type HomeSearchParams = {
  category: string;
  posttitle: string;
  carPlatenumber: string;
  userId?: string;
};

type ChatRes = {
  chats: ChatResponse[];
  count: number;
};

export const biddingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMyBId: builder.query<
      CommonResponseType & { data: { postCount: number; post: BidRes[] } },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.allMyBids}`,
        method: "GET",
      }),
    }),

    getBiddingDetail: builder.query<
      CommonResponseType & {
        data: { bidders: SeeBidders[]; postDetails: ListedPost };
      },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.biddingDetail}/${id}`,
        method: "GET",
      }),
    }),

    seeAllBidders: builder.query<
      CommonResponseType & { data: { bidders: SeeBidders[] } },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.biddingDetail}/${id}`,
        method: "GET",
      }),
    }),
    payTokenMoney: builder.mutation<
      CommonResponseType & { data: ListedPost },
      { postId: string | undefined; tokenPrice: number }
    >({
      query: (body) => ({
        url: `${END_POINTS.tokenMoney}`,
        method: "POST",
        body,
      }),
    }),
    placeBid: builder.mutation<
      CommonResponseType & { data: ListedPost },
      { postId: string | undefined; price: number }
    >({
      query: (body) => ({
        url: `${END_POINTS.makeABid}`,
        method: "POST",
        body,
      }),
    }),

    //chat
    getChatList: builder.query<CommonResponseType & { data: ChatRes }, {}>({
      query: () => ({
        url: `${END_POINTS.chatList}`,
        method: "GET",
      }),
    }),

    getChatHistory: builder.query<
      CommonResponseType & { data: ChatHistoryResponse[] },
      { connectionId: string }
    >({
      query: ({ connectionId }) => ({
        url: `${END_POINTS.chatHistory}/${connectionId}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetAllMyBIdQuery,
  useLazyGetBiddingDetailQuery,
  useLazySeeAllBiddersQuery,
  usePayTokenMoneyMutation,
  usePlaceBidMutation,
  //chat
  useLazyGetChatListQuery,
  useLazyGetChatHistoryQuery,
} = biddingApi;
