import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import OTPInput from "react-otp-input";
import { isNumber } from "../../utils/validation";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../helpers";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import { useOtpVerficationMutation } from "../../service/Auth";
import { useForgotPasswordMutation } from "../../service/Auth";
import useTranslation from "../../hooks/Translation";

type Props = {
  closeModal: () => void;
};

const otpStyle = {
  width: "16%",
  height: "70px",
  "box-sizing": "border-box",
  border: "1px solid #1d1d1d4f",
  padding: "10px",
};

type CountryType = {
  code: string;
  icon: string;
  id: string;
  countryCode: string;
};

const ForgotPassword = ({ closeModal }: Props) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [forgotMutation, { isLoading }] = useForgotPasswordMutation();
  const [otpVerification, otpVerificationData] = useOtpVerficationMutation();

  const [selectedCountry, setSelectedCountry] = useState<CountryType>({
    code: "(SA) +966",
    icon: "/static/images/su_flag.webp",
    id: "2",
    countryCode: "+966",
  });
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(30);
  const [email, setEmail] = useState(false);
  const [otpsend, setOtpSend] = useState(false);
  const [error, setError] = useState(false);

  const countryCodes = [
    {
      code: "(IND) +91",
      icon: "/static/images/in_flag.png",
      id: "1",
      countryCode: "+91",
    },
    {
      code: "(SA) +966",
      icon: "/static/images/su_flag.webp",
      id: "2",
      countryCode: "+966",
    },
  ];

  const handleChangeVendorSelect = (event: any, newValue: any) => {
    setSelectedCountry(newValue);
    formik.setFieldValue("phoneNumber", "");
  };

  // const handleSubmit = () => {
  //   closeModal();
  //   dispatch(toggleAuthModal({ isAuthModalVisible: true, ModalType: "reset" }));
  // };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  const formik = useFormik({
    initialValues: {
      selectedCountry: "",
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required(translation.validations.required_field)
        .min(9, translation.validations.min_nine)
        .max(15, translation.validations.max_fifteen),
    }),
    onSubmit: async (values: any) => {
      formik.setSubmitting(true);
      if (otpsend) {
        return;
      }
      let body = {
        phone: formik.values.phoneNumber,
        countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
      };

      try {
        const response = await forgotMutation(body).unwrap();
        if (response?.statusCode === 200) {
          setOtpSend(true);
        }
      } catch (error: any) {
        console.log("error: ", error);
        errorToast(error?.message || "");
      }
    },
  });

  const handleResend = async () => {
    let body = {
      phone: formik.values.phoneNumber,
      countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
    };

    try {
      const response = await forgotMutation(body).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.otpResend);
        setCountDown(59);
      }
    } catch (error: any) {
      console.log("error: ", error);
      errorToast(error?.message || "");
    }
  };

  const handleSubmit = async () => {
    if (!otp) {
      errorToast(translation.Auth.otp_verification.enterOtp);
    }

    if (otp?.length === 4) {
      setError(false);

      const body = {
        phone: formik.values.phoneNumber,
        countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
        code: otp,
      };

      try {
        const response = await otpVerification(body).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.tempToken,
            JSON.stringify(response?.data?.token)
          );
          dispatch(
            temporaryToken({
              tempToken: response?.data?.token || null,
            })
          );

          dispatch(
            toggleAuthModal({ isAuthModalVisible: true, ModalType: "reset" })
          );
        }
      } catch (error: any) {
        errorToast(error?.message || "");
        console.log(error);
      }
    } else {
      errorToast(translation.Auth.otp_verification.enterOtp);
    }
  };

  return (
    <div>
      <Loader isLoad={isLoading || otpVerificationData?.isLoading} />
      <div className="AuthWrap">
        <div className="cross dbl">
          <figure
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "login",
                })
              )
            }
          >
            <img src="/static/images/arrow_Img.svg" alt="img" />
          </figure>
          <figure onClick={() => closeModal()}> <CloseIcon /></figure>
        </div>
        <h2>{translation.Auth.Login.forgot_pas}</h2>
        <div style={{ padding: "22px" }} />
        <form onSubmit={formik.handleSubmit}>
          <div className="form_control">
            <div className="select_country">
              <p style={{ fontSize: "12px" }} className="select_p">
                {translation.Auth.Sign_up.countryCode}
              </p>

              <Autocomplete
                disabled={otpsend}
                className="prfl_autocmplt"
                disablePortal
                id="combo-box-demo"
                onChange={handleChangeVendorSelect}
                options={countryCodes}
                getOptionLabel={(option) => `${option?.code}`}
                sx={{ width: "auto" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={translation.Auth.Sign_up.selectCountry}
                  />
                )}
                // defaultValue={defaultSelectedCountry}
                defaultValue={countryCodes.find((option) =>
                  option.code.includes("(SA) +966")
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div className="contryCode-list">
                      <img src={option?.icon} alt="img" />
                      <span>{option?.code || ""}</span>
                    </div>
                  </li>
                )}
              />

              <div className="horizontal_line" />

              <TextField
                disabled={otpsend}
                inputProps={{
                  maxLength: selectedCountry?.countryCode === "+91" ? 10 : 15,
                }}
                fullWidth
                className="phoneField"
                hiddenLabel
                variant="outlined"
                placeholder={translation.Globals.phone_number}
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isNumber(val.target.value)) {
                    formik.setFieldValue("phoneNumber", val.target.value);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </div>

            <div style={{ marginBottom: otpsend ? 10 : 50 }} />

            {otpsend ? (
              <div>
                <p className="otp_txt">
                  {translation.Auth.otp_verification.otp}
                </p>

                <div className="entr_otp">
                  <FormControl className="opt_input" sx={{ width: "100%" }}>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={otpStyle}
                      inputType="tel"
                      containerStyle={{
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    />
                    <br />
                  </FormControl>
                </div>

                <div>
                  {countDown === 0 ? (
                    <p className="resend">
                      {translation.Auth.otp_verification.dontGetOtp}{" "}
                      <span
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={handleResend}
                      >
                        {translation.Auth.otp_verification.resend}
                      </span>
                    </p>
                  ) : (
                    <p className="counter">
                      {countDown < 10
                        ? `00: 0${countDown}`
                        : `00: ${countDown}`}
                    </p>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <Button
            disabled={!formik.values.phoneNumber}
            value={
              !otpsend
                ? translation.Auth.otp_verification.sendOtp
                : translation.Globals.submit
            }
            onClick={() => {
              if (otpsend) {
                handleSubmit();
              } else {
                setError(true);
              }
            }}
          />
        </form>
        <div style={{ padding: "10px" }} />
      </div>
    </div>
  );
};

export default ForgotPassword;
