import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  BidderCard,
  Button,
  CarNumberPlate,
  PlaceBidModal,
  PlateDetailCard,
} from "../../components";
import {
  useLazyGetBiddingDetailQuery,
  usePlaceBidMutation,
} from "../../service/bidding";
import { BidRes, ListedPost, SeeBidders } from "../../types/General";
import { useLocation, useParams } from "react-router-dom";
import { Loader, errorToast } from "../../helpers";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const BidDetail = () => {
  const user = useAuth();
  const { id } = useParams();
  const location = useLocation();
  const translation = useTranslation();

  const [makeBid, makeBidData] = usePlaceBidMutation();
  const [open, setOpen] = useState(false);
  const [bidPlaced, setBidPlaced] = useState(false);
  const [bidAmount, setBidAmount] = useState<string>("");
  const [openWarn, setOpenWarn] = useState(false);

  const [biddingdetail, { isLoading }] = useLazyGetBiddingDetailQuery();
  const [allBidders, setAllBidders] = useState<SeeBidders[]>([]);
  const [postDetail, setPostDetail] = useState<ListedPost | null>(null);
  const [currentBid, setCurrentBid] = useState<number>(0);

  //make a bid

  const handleAddBid = async () => {
    if (id) {
      let data = {
        postId: id,
        price: Number(bidAmount),
      };

      try {
        const response = await makeBid(data).unwrap();
        if (response?.statusCode === 200) {
          setOpen(false);
          getAllBidDetail(id);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
    }
  };

  const getAllBidDetail = async (id: string) => {
    try {
      const response = await biddingdetail({ id }).unwrap();
      if (response?.statusCode === 200) {
        setAllBidders(response?.data?.bidders || []);
        setPostDetail(response?.data?.postDetails);
        setCurrentBid(
          response?.data?.bidders?.length
            ? response?.data?.bidders?.[0]?.price
            : 0
        );
        if (allBidders?.length) {
          setBidAmount(String(currentBid + 1));
        } else {
          setBidAmount(response?.data?.postDetails?.startingBid);
        }
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getAllBidDetail(id);
    }
  }, []);

  useEffect(() => {
    if (allBidders?.length) {
      setBidAmount(String(currentBid + 1));
    }
  }, [currentBid, allBidders]);
  return (
    <Layout>
      <Loader isLoad={makeBidData?.isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <h1 className="prdct_dtl_hdng">{translation.bids.bidDetail}</h1>
            <div className="bidMain">
              <div className="detail_main">
                <div className="dLeft">
                  <CarNumberPlate
                    category={postDetail?.category}
                    posttitle={postDetail?.posttitle}
                    carPlatenumber={postDetail?.carPlatenumber}
                  />
                </div>
                <div className="dRyt">
                  {/* <PlateDetailCard
                  // state={state}
                  posttitle={state?.postDetails?.posttitle}
                  price={state?.postDetails?.price}
                  carPlatenumber={state?.postDetails?.carPlatenumber}
                  _id={state?.postDetails?._id}
                  role={state?.postDetails?.role}
                  postCreatedBy={state?.postDetails?.userId}
                  createdAt={state?.postDetails?.createdAt}
                  typeOfPrice={state?.postDetails?.typeOfPrice}
                  setSelectedId={setSelectedId}
                  setOpenWarn={setOpenWarn}
                  // listedPost={state?.postDetails}
                  // handleFav={handleFav}
                /> */}
                </div>
              </div>
              <div className="plateDetail">
                <div className="location">
                  <figure>
                    <img src="/static/images/location.svg" />
                  </figure>
                  <h3>{postDetail?.postLocation || "N/A"}</h3>
                </div>
                <div className="des">
                  <p>{postDetail?.meaningOfPlate || ""}</p>
                </div>
                <div className="time">
                  <h4>
                    {translation.posts.endAuction}:{" "}
                    <span className="">
                      {" "}
                      {moment(postDetail?.bidingStartTime).format("llll")}
                    </span>
                  </h4>
                </div>
              </div>
              <div className="bidderList">
                <div className="biddertitle">
                  <h2 className="prdct_dtl_hdng">{translation.bids.bidders}</h2>
                  {allBidders?.length ? (
                    <h4>
                      {translation.bids.currentBid}{" "}
                      <span className="">{currentBid} SAR</span>
                    </h4>
                  ) : null}
                </div>
                <div className="bidder_box">
                  {allBidders?.length ? (
                    allBidders?.map((item) => <BidderCard item={item} />)
                  ) : (
                    <div className="noData">
                      <figure>
                        <img src="/static/images/noData.webp" alt="" />
                      </figure>
                      <h3> {translation.error.noBidders}</h3>
                    </div>
                  )}
                </div>
              </div>
              {allBidders?.length &&
              user?._id === allBidders?.[0]?._id ? null : (
                <>
                  {allBidders?.length ? (
                    <div className="showBid">
                      <div className="sbLeft">
                        <h5>{translation.bids.bidOutBid}</h5>
                        <h2>
                          <span className="">{currentBid || "0"}</span> SAR
                        </h2>
                      </div>
                      <div className="sbRyt">
                        <Button
                          value={translation.bids.increaseMaxBid}
                          onClick={() => setOpen(true)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="btn-payment">
                      <Button
                        value={translation.bids.placeBid}
                        onClick={() => setOpen(true)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <PlaceBidModal
            open={open}
            setOpen={setOpen}
            setBidPlaced={setBidPlaced}
            currentBid={currentBid}
            setBidAmount={setBidAmount}
            bidAmount={bidAmount}
            handleAddBid={handleAddBid}
            allBidders={allBidders}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BidDetail;
