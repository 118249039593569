import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Routing from "./Routes";
import { useAppDispatch, useAppSelector } from "./hooks/store";
import { STORAGE_KEYS, getFromStorage } from "./helpers";
import { getTempToken, getToken } from "./reducers/authSlice";
import { useLazyGetCmsQuery } from "./service/Common";
import { resetCms } from "./reducers/generalSlice";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";
import { useNavigate } from "react-router-dom";
import { socket } from "./utils/socket";
import LanguageContextProvider from "./context/LanguageContext";

function App() {
  const navigate = useNavigate();
  const [GetCms] = useLazyGetCmsQuery();
  const dispatch = useAppDispatch();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const socketToken = useAppSelector(getToken);

  const fetchCmsData = async () => {
    try {
      const response = await GetCms({}).unwrap();

      if (response?.statusCode === 200) {
        dispatch(resetCms({ cms: response?.data }));
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  // console.log = () => {};

  useEffect(() => {
    fetchCmsData();
  }, []);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    const playNotificationSound = () => {
      const audio = new Audio("/static/sounds/notification_sound.mp3"); // Specify the path to your notification sound
      audio.play();
    };

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          playNotificationSound();
          return toast(
            <div
              style={{ height: "100%" }}
              onClick={() => navigate("/notifications")}
            >
              <div>{event?.data?.data?._title}</div>
              <div>{event?.data?.data?._message}</div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  function onConnect() {
    console.log("yes connected");
  }

  function onDisconnect() {
    console.log("disconnected");
  }

  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  useEffect(() => {
    if (socketToken?.length) {
      const modifiedURL = `${socket.io.uri}?token=${socketToken || ""}`;

      socket.io.uri = modifiedURL;

      console.log("yes ", socket);
      if (!socket?.connected) {
        socket.connect();
      }

      socket.on("connect", onConnect);
      console.log("yes 3", modifiedURL);
      socket.on("disconnect", onDisconnect);
      console.log("yes 4", modifiedURL);
      socket.on("error", onError);

      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    } else {
      socket.disconnect();
    }
  }, [socketToken]);

  return (
    <LanguageContextProvider>
      <Routing />
    </LanguageContextProvider>
  );
}

export default App;
