import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import Button from "./Button";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const AddCardModal = ({ open, setOpen }: props) => {
  const style = generateResponsiveStyle();
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <h2>ADD NEW CARD</h2>
            <p>Enter your credit card details.</p>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className="custom_label2">
                  Card holder name
                </Typography>
                <TextField
                  fullWidth
                  id="standard-basic"
                  placeholder="Card holder name"
                  variant="standard"
                  className="paymnt_modl_txtfld"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className="custom_label2">
                  Card Number
                </Typography>
                <TextField
                  fullWidth
                  id="standard-basic"
                  placeholder="Card Number"
                  variant="standard"
                  className="paymnt_modl_txtfld"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <figure>
                          <img src="/static/images/visa.png" alt="" />
                        </figure>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingLeft: "16px",
                  paddingTop: "16px",
                }}
              >
                <Grid item xs={4}>
                  <Typography variant="h6" className="custom_label2">
                    Expiry Date
                  </Typography>
                  <TextField
                    className="paymnt_modl_txtfld"
                    variant="standard"
                    placeholder="MM/YYYY"
                    inputProps={{ maxLength: 7 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className="custom_label2">
                    CVV
                  </Typography>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    placeholder="CVV"
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <figure className="cvv_img">
                            <img src="/static/images/cvv.svg" alt="" />
                          </figure>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Box>
              <Grid item xs={12} className="sv_crd">
                <FormControlLabel
                  control={<Checkbox />}
                  // checked={formik.values.remember}
                  name="remember"
                  // onChange={formik.handleChange}
                  label="Save this card for future payments?"
                />
              </Grid>
              <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                <Button value="Save" onClick={() => setOpen(false)} />
              </Box>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCardModal;
