import { Box, Modal, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/AuthModalStyle";
import { isNumber } from "../utils/validation";
import Button from "./Button";
import useTranslation from "../hooks/Translation";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setOfferPrice: Dispatch<SetStateAction<string>>;
  handleSubmit: () => void;
  offerPrice: string;
};

const AddOfferModal = ({
  open,
  setOpen,
  handleSubmit,
  setOfferPrice,
  offerPrice,
}: props) => {
  const style = generateResponsiveStyle();
  const translation = useTranslation();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "16px" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px" }}
          >
            {translation.bids.addOffer1}
          </Typography>

          <div className="textFied">
            <TextField
              placeholder={translation.bids.addOffer1}
              className="text_field"
              autoFocus
              margin="dense"
              id="name"
              name="email"
              type="text"
              fullWidth
              variant="standard"
              inputProps={{
                maxLength: 10,
              }}
              value={offerPrice}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isNumber(val.target.value)) {
                  setOfferPrice(val.target.value);
                }
              }}
            />
            <div className="addofferbtn">
              <Button
                value={translation.Globals.submit}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddOfferModal;
