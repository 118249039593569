import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import ProtectedRoutes from "./utils/protectedRoutes";

const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.HomePage />} />
        <Route path="/car-plate-detail/:id" element={<Pages.PlateDetail />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Pages.Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/seller-detail/:id"
          element={
            <ProtectedRoutes>
              <Pages.SellerDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoutes>
              <Pages.Chat />
            </ProtectedRoutes>
          }
        />
        <Route path="/filter" element={<Pages.Filters />} />
        <Route
          path="/notifications"
          element={
            <ProtectedRoutes>
              <Pages.Notifications />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoutes>
              <Pages.PaymentMethod />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/my-bids"
          element={
            <ProtectedRoutes>
              <Pages.MyBids />
            </ProtectedRoutes>
          }
        />

        <Route path="/search" element={<Pages.Search />} />
        <Route path="/comments/:id" element={<Pages.Comments />} />
        <Route
          path="/bid-detail/:id"
          element={
            <ProtectedRoutes>
              <Pages.BidDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/services"
          element={
            <ProtectedRoutes>
              <Pages.Services />
            </ProtectedRoutes>
          }
        />
        <Route path="/review" element={<Pages.Review />} />
        <Route
          path="/booking-details"
          element={
            <ProtectedRoutes>
              <Pages.BookingDetail />
            </ProtectedRoutes>
          }
        />
        <Route path="/about-us" element={<Pages.AboutUs />} />
        <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
        <Route path="/terms-&-conditions" element={<Pages.Terms />} />
        <Route path="/contact-us" element={<Pages.ContactUs />} />
        <Route path="/see-all" element={<Pages.SeeAllPage />} />
        <Route
          path="/post-for-sell"
          element={
            <ProtectedRoutes>
              <Pages.AddPostSell />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/post-for-sell/:id"
          element={
            <ProtectedRoutes>
              <Pages.AddPostSell />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/post-for-auction"
          element={
            <ProtectedRoutes>
              <Pages.AddAuction />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/post-for-auction/:id"
          element={
            <ProtectedRoutes>
              <Pages.AddAuction />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/post-for-offer"
          element={
            <ProtectedRoutes>
              <Pages.AddOfferPost />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/post-for-offer/:id"
          element={
            <ProtectedRoutes>
              <Pages.AddOfferPost />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/view-offer/:id"
          element={
            <ProtectedRoutes>
              <Pages.ViewOffer />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/service-agents/:id"
          element={
            <ProtectedRoutes>
              <Pages.AgentForService />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/bidders-list/:id"
          element={
            <ProtectedRoutes>
              <Pages.SeeAllBidders />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
};
export default Routing;
