import React, { useEffect, useState } from "react";
import { CarPlateCard } from "../../components";
import cardData from "../../data/ProductData.json";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../../types/General";
import useTranslation from "../../hooks/Translation";

type props = {
  dashboardData: Dashboard | null;
  loading: boolean;
};

const AuctionPost = ({ dashboardData, loading }: props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const binaryData = dashboardData?.auctionAds || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  console.log(dashboardData, "dashboardData");


  useEffect(() => {
    checkFavProperties();
  }, [dashboardData?.auctionAds]);

  return (
    <section className="prodcts_sc container ">
      <div className="prdct_hdngs pt">
        <h2>{translation?.home?.auctionPost}</h2>
        {dashboardData?.auctionAds?.length &&
          dashboardData?.auctionAds?.length > 5 ? (
          <p
            // onClick={() => navigate("/filter", { state: "" })}
            onClick={() =>
              navigate("/see-all", {
                state: {
                  key: "Auction Post",
                  type: "auctionAds",
                },
              })
            }
          >
            {translation?.home.seeAll}
          </p>
        ) : null}
      </div>
      <div className="crds_sec">
        {!loading && !dashboardData?.auctionAds?.length ? (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3> {translation?.error.noData}</h3>
          </div>
        ) : dashboardData?.auctionAds?.length ? (
          dashboardData?.auctionAds
            ?.slice(0, 5)
            .map((item) => (
              <CarPlateCard
                Auction={true}
                category={item?.categoryData?.categoryName}
                posttitle={item?.posttitle}
                price={item?.price}
                carPlatenumber={item?.carPlatenumber}
                _id={item?._id}
                setFavItems={setFavItems}
                favItems={favItems}
                item={item}
                likeItems={likeItems}
                setLikeItems={setLikeItems}
                role={item?.role}
              />
            ))
        ) : null}
      </div>
    </section>
  );
};

export default AuctionPost;
