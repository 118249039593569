import React, { useEffect, useState } from "react";
import { letters, number } from "../utils/validation";
import { useLazyGetAllCateQuery } from "../service/posts";
import { Category } from "../types/General";
import { errorToast } from "../helpers";
import useTranslation from "../hooks/Translation";

type props = {
  category?: string;
  posttitle?: string;
  carPlatenumber?: string;
};

const CarNumberPlate = ({ category, posttitle, carPlatenumber }: props) => {
  const translation = useTranslation();
  const [allCategory] = useLazyGetAllCateQuery();

  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const [selectedId, setSelectedId] = useState("");

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data);
        setSelectedId(response?.data?.[0]?._id || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <div className="plate_box">
      <ul className="pLft">
        <li className="pro_sans">
          {" "}
          {carPlatenumber
            ?.split("")
            /* @ts-ignore */
            ?.map((item: string) => number[`${item}`])
            ?.reverse()
            ?.join(" ")}
        </li>
        <li className="pro_sans">
          {posttitle
            ?.split("")
            /* @ts-ignore */
            ?.map((item: string) => letters[`${item?.toLowerCase()}`])
            ?.reverse()
            ?.join(" ")}
        </li>
        <li className="pro_sans">
          {" "}
          {carPlatenumber ? carPlatenumber.split("").join(" ") : ""}
        </li>
        <li className="pro_sans">
          {" "}
          {posttitle ? posttitle.split("").join(" ") : ""}
        </li>
      </ul>
      <div
        className="pRyt"
        style={{
          background: category === selectedId ? "#3c82c8" : "",
        }}
      >
        <figure>
          <img src="/static/images/lowhate_icon.png" alt="" />
        </figure>
        <p>السعودية</p>
        <div className="ksa">
          <h4>K</h4>
          <h4>S</h4>
          <h4>A</h4>
        </div>
        {category === selectedId ? (
          <figure className="arrow">
            <img src="/static/images/downArrow.svg" />
          </figure>
        ) : (
          <div className="greenDot" />
        )}
      </div>
    </div>
  );
};

export default CarNumberPlate;
