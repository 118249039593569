import io from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
// const URL = ``; //live
const URL = `https://lowhateapi.appgrowthcompany.com`; //dev
// const URL = "https://lowhateapi.appgrowthcompany.com:6547/";

export const socket = io(URL, {
  autoConnect: false,
});
