export const API_URL = "https://lowhateapi.appgrowthcompany.com/api/v1/user/"; // development

export const GOOGLE_API_KEY = "AIzaSyCES0pCZNW9kCYzWiq4g0oDvSjPQUAyLVY";

export const END_POINTS = {
  checkWalletAddress: "checkWalletAddress",
  signUp: "signup",
  login: "login",
  mediaUpload: "upload",
  verify_otp: "verifyotp",
  update_profile: "updateprofile",
  get_profile: "profile",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
  changePassword: "changepassword",
  logout: "logout",
  update_profile_by_id: "updateProfileById",

  //
  cms: "cms",
  notification: "notification",

  // post
  sellPost: "sellPost",
  auctionPost: "auctionPost",
  auctionPostStatus: "auctionPostStatus",
  createOffer: "createOffer",
  getPostById: "getPostById",
  getOffer: "getOffer",
  makePostOffer: "offer",

  category: "categoryList",
  serviceList: "serviceList",
  mywishlist: "mywishlist",
  addorRemovefromWishlist: "addorRemovefromWishlist",
  addorRemovelike: "addorRemovelike",
  dashboard: "dashboard",

  myEarnings: "myEarnings",
  myEarningGraph: "myEarningGraph",
  review: "review",
  homeSearchApi: "searchPost",
  notifyUser: "notifyUser",
  viewAllPost: "viewAllPost",
  comment: "comment",
  advertisementPost: "advertisementPost",
  closePost: "closePost",

  //bid
  allMyBids: "allMyBids",
  makeABid: "bid",
  biddingDetail: "biddingDetail",

  //sell service
  sellService: "sellService",
  allServices: "allServices",

  //profile
  address: "address",
  allAddress: "Alladdress",
  agent: "agent",

  //subscription
  subscription: "subscription",
  tokenMoney: "tokenMoney",
  getuserDetails: "getuserDetails",

  //chat
  chatList: "chatList",
  chatHistory: "chat",
  settings: "settings",
};
