import { Navigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "../helpers";

const ProtectedRoutes = ({ children }: any) => {
  if (getFromStorage(STORAGE_KEYS.token)) {
    return children;
  }
  return <Navigate to="/" replace />;
};

export default ProtectedRoutes;
