import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Loader, errorToast } from "../../helpers";
import { useLazyGetMyReviewQuery } from "../../service/home";
import { MyReview } from "../../types/General";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import useTranslation from "../../hooks/Translation";

const ManageReviews = () => {
  const translation = useTranslation();
  const [getReview, { isLoading }] = useLazyGetMyReviewQuery();
  const [myReviews, setMyReviews] = useState<MyReview[]>([]);
  const [avgRating, setAvgRating] = useState<number | undefined>();
  const [reviewCount, setReviewCount] = useState<number | undefined>();

  const getMyReview = async () => {
    try {
      const response = await getReview({}).unwrap();
      if (response?.statusCode === 200) {
        setMyReviews(response?.data?.review || []);
        setAvgRating(response?.data?.avgRating || 0);
        setReviewCount(response?.data?.reviewCount || 0);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const renderStars = () => {
    const stars = [];
    const roundedAvgRating = Math.round(avgRating || 0); // Round average rating
    for (let i = 0; i < 5; i++) {
      if (i < roundedAvgRating) {
        stars.push(<StarRoundedIcon key={i} />);
      } else {
        stars.push(<StarOutlineRoundedIcon key={i} />);
      }
    }
    return stars;
  };

  useEffect(() => {
    getMyReview();
  }, []);

  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <div className="head">
        <h1>{`${avgRating}.0` || 0}</h1>
        <div className="starshow">{renderStars()}</div>

        <img className="users" src="/static/images/user1.png" />
        <p>
          {reviewCount || 0} {translation.profile.users}
        </p>
      </div>

      <div className={myReviews?.length ? "all_ratings" : "crds_sec_noData"}>
        {myReviews?.length ? (
          myReviews?.map((item) => (
            <div key={item?._id}>
              <div className="review_card">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={item?.image || ""} />
                  <div style={{ paddingLeft: "12px" }}>
                    <p className="title">{item?.fullname}</p>
                    <p />
                    {/* <p className="message">{item?.message}</p> */}
                    <Rating
                      defaultValue={item?.rating}
                      size="small"
                      sx={{ color: "#204E33" }}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="horizontal_line"></div>
            </div>
          ))
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3> {translation.error.noData}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageReviews;
