import React, { useState } from "react";
import { AddCardModal, Button } from "../../components";
import "../profile/profile.scss";
import Layout from "../../layout";
import { Radio } from "@mui/material";

const PaymentMethod = () => {
  const [open, setOpen] = useState<boolean>(false);

  const data = [
    {
      id: 1,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 2,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 3,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 4,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
  ];
  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <h2 className="prdct_dtl_hdng">Manage Payments</h2>
            <div className="card_container">
              <ul className="cards_sec">
                {data?.map((item) => {
                  return (
                    <li className="mn_crds">
                      <div className="crd_dtls">
                        <figure>
                          <img src={item?.card_img} alt="" />
                        </figure>
                        <div className="dtl_mn">
                          <h4>{item?.card_number || ""}</h4>
                          <p>{item?.description}</p>
                        </div>
                      </div>
                      <Radio />
                    </li>
                  );
                })}
              </ul>
              <div className="add_crd" onClick={() => setOpen(true)}>
                <figure>
                  <img src="/static/images/add_card.svg" alt="" />
                </figure>
                <div className="add_details">
                  <h4>Add new card</h4>
                  <p>Save and Pay via Cards.</p>
                  <div className="paymnt_cards">
                    <figure>
                      <img src="/static/images/visa.svg" />
                    </figure>
                    <figure>
                      <img src="/static/images/master-card.svg" />
                    </figure>
                    <figure>
                      <img src="/static/images/discover.svg" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="btn-payment">
                <Button value="Update" />
              </div>
            </div>
            <AddCardModal open={open} setOpen={setOpen} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentMethod;
