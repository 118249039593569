import React, { Dispatch, SetStateAction } from "react";
import { Button, InputField } from "../../components";
import "../auth/Auth.scss";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  removeFromStorage,
  successToast,
} from "../../helpers";
import { temporaryToken } from "../../reducers/authSlice";
import { useResetPasswordMutation } from "../../service/Auth";
import useTranslation from "../../hooks/Translation";

type Props = {
  closeModal: () => void;
};

const ResetPassword = ({ closeModal }: Props) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const [postResetPassword, { isLoading }] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(translation.validations.password_required)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.password_validation
        ),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], translation.validations.password_match)
        .required(translation.validations.cofirmPass_required),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      const data = {
        password: formik.values.password,
      };

      try {
        const response = await postResetPassword(data).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          removeFromStorage(STORAGE_KEYS.tempToken);

          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );

          dispatch(
            toggleAuthModal({
              isAuthModalVisible: true,
              ModalType: "login",
            })
          );
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="AuthWrap">
        <div className="cross dbl">
          <figure
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "forgot",
                })
              )
            }
          >
            <img src="/static/images/arrow_Img.svg" />
          </figure>
          <CloseIcon />
        </div>
        <h2>{translation.Auth.reset_password.reset}</h2>
        <div style={{ marginBottom: 20 }} />
        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder={translation.Auth.reset_password.new_password}
            password
            name="password"
            id="password"
            value={formik.values.password}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.password && formik.errors.password}
          />
          <InputField
            placeholder={translation.Auth.change_password.change}
            password
            name="confirmPassword"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <div style={{ marginTop: 20 }} />
          <Button value={translation.Globals.update} />
        </form>
        <div className="mrgn_Btm" style={{ marginBottom: 100 }} />
      </div>
    </div>
  );
};

export default ResetPassword;
