import React, { Dispatch, SetStateAction, useState } from "react";
import { Category } from "../../types/General";

type props = {
  category: Category[];
  postType: boolean;
  setPostType: Dispatch<SetStateAction<boolean>>;
  selectedId: string;
  setSelectedId: Dispatch<SetStateAction<string>>;
};

const ChoosePlate = ({
  category,
  setPostType,
  postType,
  setSelectedId,
  selectedId,
}: props) => {
  return (
    <div className="choosePost container">
      {category?.length
        ? category?.map((item, i) => (
            <div
              className="box"
              onClick={() => {
                if (item?.categoryName === "Public Car") {
                  setPostType(false);
                  setSelectedId(item?._id);
                } else {
                  setPostType(true);
                  setSelectedId(item?._id);
                }
              }}
            >
              <div className="check_mark">
                {item?.categoryName == "Public Car" ? (
                  <figure>
                    <img
                      src={
                        !postType
                          ? "/static/images/tick.png"
                          : "/static/images/no_tick.png"
                      }
                      alt=""
                    />
                  </figure>
                ) : null}
                {item?.categoryName !== "Public Car" ? (
                  <figure>
                    <img
                      src={
                        postType
                          ? "/static/images/tick.png"
                          : "/static/images/no_tick.png"
                      }
                      alt=""
                    />
                  </figure>
                ) : null}

                <h3>{item?.categoryName || ""}</h3>
              </div>
              <figure>
                {item?.categoryName === "Public Car" ? (
                  <img src="/static/images/public_car.png" alt="" />
                ) : (
                  <img src="/static/images/private_car.png" alt="" />
                )}
              </figure>
            </div>
          ))
        : null}
    </div>
  );
};

export default ChoosePlate;
