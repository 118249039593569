import secureLocalStorage from "react-secure-storage";
export const STORAGE_KEYS = {
  token: "TOKEN",
  userData: "USER_DATA",
  credentials: "REMEMBER_ME",
  tempToken: "TEMP_TOKEN",
  fcmToken: "FCM_TOKEN",
  language: "LANGUAGE",
  isAuthrorised: "IS_AUTH",
  WalletAddress: "WALLET_ADDRESS",
};

export const SOCKET_KEYS = {
  text: "TEXT",
  image: "IMAGE",
  video: "VIDEO",
};

export const setToStorage = (key: string, data: string) => {
  secureLocalStorage.setItem(key, data);
};

export const getFromStorage = (key: string) => {
  return secureLocalStorage.getItem(key);
};

export const removeFromStorage = (key: string) => {
  secureLocalStorage.removeItem(key);
};
