import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useLazyGetSellerDetailQuery } from "../../service/posts";
import { useParams } from "react-router-dom";
import { SellerDetailRes } from "../../types/General";
import { Rating } from "@mui/material";
import { CarPlateCard } from "../../components";
import useTranslation from "../../hooks/Translation";

const SellerDetail = () => {
  const { id } = useParams();
  const translation = useTranslation();

  const [sellerDetailMethod] = useLazyGetSellerDetailQuery();

  const [detail, setDetail] = useState<SellerDetailRes | null>(null);
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const getSellerDetail = async (id: string) => {
    try {
      const result = await sellerDetailMethod({ id }).unwrap();
      if (result?.statusCode === 200) {
        setDetail(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getSellerDetail(id);
    }
  }, [id]);

  const checkFavProperties = () => {
    const binaryData = detail?.postforAuction || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [detail?.postforAuction]);

  return (
    <Layout>
      {/* <Loader isLoad={makeBidData?.isLoading} /> */}
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <h1 className="prdct_dtl_hdng">{translation.posts.sellerDetail}</h1>
            <div className="sllerDetailWrapper">
              <div className="selleImg">
                <figure>
                  <img
                    style={{ borderRadius: "100%" }}
                    src={
                      // listedPost?.userImage ||
                      "/static/images/userDiv.png"
                    }
                  />
                </figure>
              </div>
              <div className="sellerDeail">
                <ul>
                  <li>
                    <h2>{translation.Globals.name}</h2>{" "}
                    <h2>{detail?.fullName || ""}</h2>
                  </li>
                  <li>
                    <h2>{translation.Globals.email}</h2>{" "}
                    <h2>{detail?.email || ""}</h2>
                  </li>
                  <li>
                    <h2>{translation.Globals.phone_number}</h2>{" "}
                    <h2>{detail?.phone || ""}</h2>
                  </li>
                  <li>
                    <h2>{translation.Globals.address}</h2>{" "}
                    <h2>{detail?.address || ""}</h2>
                  </li>
                  <li>
                    <h2>{translation.Globals.rating}</h2>{" "}
                    <h2>
                      {" "}
                      <Rating
                        defaultValue={detail?.ratingCount || 1}
                        size="small"
                        sx={{ color: "#204E33" }}
                        readOnly
                      />
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
            <div className="showsellerPost">
              <h2>{translation.home.auctionPost}</h2>
              <div className="crds_sec-seller">
                {detail?.postforAuction?.length ? (
                  detail?.postforAuction?.map((item) => (
                    <CarPlateCard
                      category={item?.categoryData?.categoryName}
                      posttitle={item?.posttitle}
                      price={item?.price}
                      carPlatenumber={item?.carPlatenumber}
                      _id={item?._id}
                      setFavItems={setFavItems}
                      favItems={favItems}
                      item={item}
                      likeItems={likeItems}
                      setLikeItems={setLikeItems}
                      role={item?.role}
                    />
                  ))
                ) : (
                  <div className="noData">
                    <figure>
                      <img src="/static/images/noData.webp" alt="" />
                    </figure>
                    <h3> {translation.error.noData}</h3>
                  </div>
                )}
              </div>

              <h2>{translation.home.sellPosts}</h2>
              <div className="crds_sec-seller">
                {detail?.postforSell?.length ? (
                  detail?.postforSell?.map((item) => (
                    <CarPlateCard
                      category={item?.categoryData?.categoryName}
                      posttitle={item?.posttitle}
                      price={item?.price}
                      carPlatenumber={item?.carPlatenumber}
                      _id={item?._id}
                      setFavItems={setFavItems}
                      favItems={favItems}
                      item={item}
                      likeItems={likeItems}
                      setLikeItems={setLikeItems}
                      role={item?.role}
                    />
                  ))
                ) : (
                  <div className="noData">
                    <figure>
                      <img src="/static/images/noData.webp" alt="" />
                    </figure>
                    <h3> {translation.error.noData}</h3>
                  </div>
                )}
              </div>

              <h2>
                {" "}
                <h3> {translation.home.offerPost}</h3>
              </h2>
              <div className="crds_sec-seller">
                {detail?.offerPosts?.length ? (
                  detail?.offerPosts?.map((item) => (
                    <CarPlateCard
                      category={item?.categoryData?.categoryName}
                      posttitle={item?.posttitle}
                      price={item?.price}
                      carPlatenumber={item?.carPlatenumber}
                      _id={item?._id}
                      setFavItems={setFavItems}
                      favItems={favItems}
                      item={item}
                      likeItems={likeItems}
                      setLikeItems={setLikeItems}
                      role={item?.role}
                    />
                  ))
                ) : (
                  <div className="noData">
                    <figure>
                      <img src="/static/images/noData.webp" alt="" />
                    </figure>
                    <h3> {translation.error.noData}</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SellerDetail;
