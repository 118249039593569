import React from "react";
import Layout from "../../layout";
import "../contactUs/Cms.scss";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { getCms } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";

const AboutUs = () => {
  const navigate = useNavigate();
  const getCmsData = useAppSelector(getCms);
  const translation = useTranslation();

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card" style={{ paddingTop: 0 }}>
          <div className=" about_us">
            <div className="about_flex">
              <div className="about_lft">
                <div className="about_lft_udr">
                  <h1>What is Lowhate?</h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing <br />
                    and typesetting industry.
                  </p>
                </div>
              </div>
              <div className="about_rgt">
                <figure>
                  <img src="/static/images/about_us.png" />
                </figure>
              </div>
            </div>

            <div className="about_td">
              <h2>
                Lorem Ipsum is simply dummy text of the printing and <br />
                typesetting industry.
              </h2>

              <Button value="Get Started" onClick={() => navigate("/")} />
            </div>

            <div className="common_policy ">
              <div className="about_content container">
                <h2> {translation.footer.about_us}</h2>
                <p
                  className={getCmsData ? "about_txt" : "about_txt2"}
                  dangerouslySetInnerHTML={{
                    __html: getCmsData
                      ? getCmsData?.legal
                      : translation.Globals.no_info,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
