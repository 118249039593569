import { END_POINTS } from "../helpers";
import {
  Category,
  ListedPost,
  PostService,
  SellService,
  SellerDetailRes,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type SellPostBody = {
  posttitle: string;
  price?: any;
  carPlatenumber: string;
  category: string;
  postLocation: string;
  postPhone: string;
  typeOfPrice: string;
  meaningOfPlate: string;
  role: string;
  postCountryCode: string;
  serviceId: string[];
};

export const postApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCate: builder.query<CommonResponseType & { data: Category[] }, {}>({
      query: () => ({
        url: END_POINTS.category,
        method: "GET",
      }),
    }),

    getPostService: builder.query<
      CommonResponseType & { data: PostService[] },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.serviceList}`,
        method: "GET",
      }),
    }),

    getWishlist: builder.query<
      CommonResponseType & { data: { post: ListedPost[] } },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.mywishlist}`,
        method: "GET",
      }),
    }),

    //sell post
    getUserSellPost: builder.query<
      CommonResponseType & { data: ListedPost[] },
      {}
    >({
      query: () => ({
        url: END_POINTS.sellPost,
        method: "GET",
      }),
    }),
    addSellPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      SellPostBody
    >({
      query: (body) => ({
        url: END_POINTS.sellPost,
        method: "POST",
        body,
      }),
    }),
    updateSellPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      { id: string; body: SellPostBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.sellPost}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    getPostDetailById: builder.query<
      CommonResponseType & { data: ListedPost },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.sellPost}/${id}`,
        method: "GET",
      }),
    }),
    sellServiceList: builder.query<
      CommonResponseType & { data: { service: SellService[] } },
      {}
    >({
      query: () => ({
        url: END_POINTS.sellService,
        method: "GET",
      }),
    }),

    sellServiceListById: builder.query<
      CommonResponseType & { data: { service: SellService[] } },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.sellService}/${id}`,
        method: "GET",
      }),
    }),
    // add service
    addMyService: builder.mutation<
      CommonResponseType & { data: { service: SellService[] } },
      { serviceId: string | undefined; serviceFees: number }
    >({
      query: (body) => ({
        url: END_POINTS.sellService,
        method: "POST",
        body,
      }),
    }),

    //add agent to service
    addAgentToService: builder.mutation<
      CommonResponseType & { data: { service: SellService[] } },
      { serviceId: string | undefined; agents: string[] }
    >({
      query: (body) => ({
        url: END_POINTS.sellService,
        method: "POST",
        body,
      }),
    }),

    deleteSellPostById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.sellPost}/${id}`,
        method: "DELETE",
      }),
    }),
    advertisePost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      { postId: string }
    >({
      query: (body) => ({
        url: END_POINTS.advertisementPost,
        method: "POST",
        body,
      }),
    }),

    closePost: builder.query<
      CommonResponseType & { data: ListedPost[] },
      { postId: string }
    >({
      query: ({ postId }) => ({
        url: `${END_POINTS.closePost}/${postId}`,
        method: "PUT",
      }),
    }),
    //auction post
    getUserAuctionPost: builder.query<
      CommonResponseType & { data: { auction: ListedPost[] } },
      { status: number }
    >({
      query: ({ status }) => ({
        url: `${END_POINTS.auctionPostStatus}?status=${status}`,
        method: "GET",
      }),
    }),
    addAuctionPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      SellPostBody
    >({
      query: (body) => ({
        url: END_POINTS.auctionPost,
        method: "POST",
        body,
      }),
    }),
    updateAuctionPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      { id: string; body: SellPostBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.auctionPost}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    getAuctionPostDetailById: builder.query<
      CommonResponseType & { data: ListedPost },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.auctionPost}/${id}`,
        method: "GET",
      }),
    }),
    deleteAuctionPostById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.auctionPost}/${id}`,
        method: "DELETE",
      }),
    }),

    //offer post
    addOfferPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      SellPostBody
    >({
      query: (body) => ({
        url: END_POINTS.createOffer,
        method: "POST",
        body,
      }),
    }),
    updateOfferPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      { id: string; body: SellPostBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.createOffer}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    getOffersOnPost: builder.query<
      CommonResponseType & { data: ListedPost[] },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getOffer}/${id}`,
        method: "GET",
      }),
    }),
    addOfferToPost: builder.mutation<
      CommonResponseType & { data: ListedPost[] },
      { offerPrice: number; postId: string }
    >({
      query: (body) => ({
        url: `${END_POINTS.makePostOffer}`,
        method: "POST",
        body,
      }),
    }),
    getSellerDetail: builder.query<
      CommonResponseType & { data: SellerDetailRes },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getuserDetails}/${id}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetUserSellPostQuery,
  useAddSellPostMutation,
  useLazyGetAllCateQuery,
  useLazyGetPostServiceQuery,
  useLazyGetPostDetailByIdQuery,
  useUpdateSellPostMutation,
  useLazyDeleteSellPostByIdQuery,
  useLazyGetWishlistQuery,
  useLazySellServiceListQuery,

  //auction
  useAddAuctionPostMutation,
  useLazyGetUserAuctionPostQuery,
  useUpdateAuctionPostMutation,
  useLazyGetAuctionPostDetailByIdQuery,
  useLazyDeleteAuctionPostByIdQuery,

  //offer
  useAddOfferPostMutation,
  useUpdateOfferPostMutation,
  useAdvertisePostMutation,
  useLazyClosePostQuery,
  useLazyGetOffersOnPostQuery,
  useAddOfferToPostMutation,

  // service
  useAddMyServiceMutation,
  useLazySellServiceListByIdQuery,
  useAddAgentToServiceMutation,
  useLazyGetSellerDetailQuery,
} = postApi;
