import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  AuctionPost,
  Banners,
  Binary,
  ChoosePlate,
  DownloadApp,
  LatestAds,
  MostLiked,
  OddNumbers,
  OfferPost,
  QuadruplePost,
  TopBanner,
  TriplePost,
} from "../../features";
import "./Home.scss";
import { useLazyGetDashboardQuery } from "../../service/home";
import { useLazyGetAllCateQuery } from "../../service/posts";
import { Category, Dashboard } from "../../types/General";
import { Loader, errorToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";

const HomePage = () => {
  const user = useAuth();
  const [dashboard] = useLazyGetDashboardQuery();
  const [allCategory] = useLazyGetAllCateQuery();

  const [category, setCategory] = useState<Category[]>([]);
  const [dashboardData, setDashboardData] = useState<Dashboard | null>(null);
  const [postType, setPostType] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState("");

  const getDashboard = async () => {
    try {
      setLoading(true);
      const res = await dashboard({
        categoryId: selectedId,
        userId: user?._id ? user?._id : "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setDashboardData(res?.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data);
        setSelectedId(response?.data?.[0]?._id || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (selectedId) {
      getDashboard();
    }
  }, [selectedId, user]);

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="wrapCls">
        <div className="topPadding">
          {/* <TopBanner /> */}
        </div>
        {dashboardData?.banner?.length ? (
          <>
            <Banners dashboardData={dashboardData} />
            <div style={{ paddingBottom: 100 }} />
          </>
        ) : null}

        <ChoosePlate
          category={category}
          setPostType={setPostType}
          postType={postType}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
        />
        <AuctionPost dashboardData={dashboardData} loading={loading} />
        <OfferPost dashboardData={dashboardData} loading={loading} />
        <LatestAds dashboardData={dashboardData} loading={loading} />
        <MostLiked dashboardData={dashboardData} loading={loading} />
        <QuadruplePost dashboardData={dashboardData} loading={loading} />
        <TriplePost dashboardData={dashboardData} loading={loading} />
        <Binary dashboardData={dashboardData} loading={loading} />
        <OddNumbers dashboardData={dashboardData} loading={loading} />
      </div>
      <DownloadApp />
    </Layout>
  );
};

export default HomePage;
