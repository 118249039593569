import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
} from "../../helpers";
import { setCredentials, userLoginRes } from "../../reducers/authSlice";
import { useUpdateProfileMutation } from "../../service/Auth";
import useTranslation from "../../hooks/Translation";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const ProfileSetup = ({ closeModal, setPhone }: props) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [profileMutation, { isLoading }] = useUpdateProfileMutation();

  const [image, setImage] = useState("");

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      userName: Yup.string()
        .required(translation.validations.required_field)
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      password: Yup.string()
        .required(translation.validations.required_field)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.password_validation
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], translation.validations.password_match)
        .required(translation.validations.required_field),
    }),
    onSubmit: async (values: any) => {
      formik.setSubmitting(true);
      let body = {
        fullName: formik.values.fullName,
        userName: formik.values.userName,
        password: formik.values.password,
      };

      try {
        const response = await profileMutation(body).unwrap();
        if (response?.statusCode === 200) {
          dispatch(
            toggleAuthModal({ isAuthModalVisible: false, ModalType: "" })
          );
          const token = getFromStorage(STORAGE_KEYS.token);
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(formik.values.userName)
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: JSON.parse(`${token}`),
            })
          );
          closeModal();
          dispatch(
            userLoginRes({
              userLogin: true,
            })
          );
          // dispatch(
          //   toggleAuthModal({
          //     isAuthModalVisible: true,
          //     ModalType: "subscription",
          //   })
          // );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross dbl">
          <figure
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "signUp",
                })
              )
            }
          >
            <img src="/static/images/arrow_Img.svg" />
          </figure>

          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2>{translation.Auth.Sign_up.register}</h2>
        <div style={{ marginBottom: 20 }} />

        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder={translation.Globals.full_name}
            name="fullName"
            id="fullName"
            value={formik.values.fullName}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.fullName && formik.errors.fullName}
            inputProps={{ maxLength: 40 }}
          />
          <InputField
            placeholder={translation.Globals.user_name}
            name="userName"
            id="userName"
            value={formik.values.userName}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.userName && formik.errors.userName}
            inputProps={{ maxLength: 40 }}
          />
          <InputField
            placeholder={translation.Auth.Login.password}
            password
            name="password"
            id="password"
            value={formik.values.password}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.password && formik.errors.password}
            inputProps={{ maxLength: 40 }}
          />
          <InputField
            placeholder={translation.Auth.Sign_up.confirm_password}
            password
            name="confirmPassword"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <div style={{ marginBottom: 15 }} />
          <Button value={translation.Globals.submit} />
        </form>
      </div>
      <div style={{ marginBottom: 60 }} />
    </div>
  );
};

export default ProfileSetup;
